import { Component, Input, HostListener, ViewChild } from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import { OptionsService, ViewRegistration } from "aes-common";
import { ColumnConfig, DataTable, DataTableOptions, ColumnDataType } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AESRemoteFunctionBlocksService, CustomRemoteFunctionBlockFilter, RemoteFunctionBlock } from "../../../services/AESRemoteFunctionBlocksService";
import { Utils } from "../../../services/Utils";

@Component({
    template: "<a [routerLink]=\"!isAdmin() ? ['/configurations/functionblocks/details', element.id] : ['/configurations/functionblocks/details', element.id, element.acctId]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.description}}</a>"
})
export class FunctionBlockDetailsLink {
    @Input()
    element: RemoteFunctionBlock;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    isAdmin(){
        return Utils.isAdminUser();
    }

    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }    
}
@Component({
    template: "<a [routerLink]=\"!isAdmin() ? ['/configurations/functionblocks/details', element.id] : ['/configurations/functionblocks/details', element.id, element.acctId]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.name}}</a>"
})
export class ShowRemoteFunctionBlockEditorCell{
    @Input()
    element: RemoteFunctionBlock;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    isAdmin(){
        return Utils.isAdminUser();
    }
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }      
}

@Component({
    selector: 'remote-function-blocks',
    templateUrl: 'remote-function-blocks.html',
    styleUrls: ['./remote-function-blocks.scss']
})
export class AESRemoteFunctionBlocks {
    static view = new ViewRegistration("Function Blocks", "/configurations/functionblocks", "swap_vert", []);
    static REMOTE_FUNCTION_BLOCK_TYPE_FILTER = "REMOTE_FUNCTION_BLOCK_TYPE_FILTER";
    static REMOTE_FUNCTION_BLOCK_OWNERSHIP_FILTER = "REMOTE_FUNCTION_BLOCK_OWNERSHIP_FILTER";
    dtOptions:DataTableOptions = {pageSize : 100, sorting: false, columnFiltering: true, filtering: false, showSelectionColumn: false, multiSelect: false};

    @ViewChild(DataTable, { static: true })
    dt : DataTable
    columnCfgs: ColumnConfig[]

    buttons: ButtonConfig[];


    gapSize: string = "10px";
    types: string[] = ["All"];
    selectedType: string;
    customFilter : CustomRemoteFunctionBlockFilter;

    constructor(public dialog: MatDialog, public httpClient: AESRemoteFunctionBlocksService,  public snackBar: MatSnackBar, 
        public options: OptionsService, private router: Router) {
        var me = this;
        for(let type of AESRemoteFunctionBlocksService.FUNCTION_BLOCK_TYPES){
            this.types.push(type);
        }
        var type = this.options.getItem(AESRemoteFunctionBlocks.REMOTE_FUNCTION_BLOCK_TYPE_FILTER,"All");
        this.selectedType = type;
        var setType = type;
        if(type == "All"){
            setType = undefined;
        }

        this.customFilter = {
            type : setType,
            "fields" : [
                "name",
                "description",
                "type",
                "lastUpdated",
                "acctId",
                "id",
                "createdBy",
                "modifiedBy"
            ]
        };
        this.buttons = [ButtonConfig.createAddButton("Create Remote Function Block", function(dt, selected){
            me.router.navigateByUrl("configurations/functionblocks/details/-1");
        }), ButtonConfig.createEditButton("Edit Remote Function Block", function(dt, selected){
            if(Utils.isAdminUser()){
                me.router.navigateByUrl("configurations/functionblocks/details/" + selected[0].id + "/" + selected[0].acctId);
            }
            else{
                me.router.navigateByUrl("configurations/functionblocks/details/" + selected[0].id);
            }
        }), ButtonConfig.createDeleteButton("Delete Remote Function Block", function(selected){
            return "Are you sure you want to delete Remote Function Block " + selected.id + "?";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar(selected.id + " Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog)];

        var columnCfgs : ColumnConfig[] = [{
        	header: "Created By",
        	field: "createdBy",
        	filterable: false,
        	visible : false
    	},{
            header: "Name",
            field: "name",
            contentComponent: ShowRemoteFunctionBlockEditorCell,
            filterable: true
        },{
            header: "Description",
            field: "description",
            contentComponent: FunctionBlockDetailsLink,
            filterable: true
        },{
            header: "Type",
            field: "type",
            visible: true,
            filterable: true,
            dataType: ColumnDataType.LIST,
            listValues: AESRemoteFunctionBlocksService.FUNCTION_BLOCK_TYPES  
        },{
            header: "Last Updated",
            field: "lastUpdated",
            dataType: ColumnDataType.DATE,
            renderContent: function(col: ColumnConfig, element: RemoteFunctionBlock){
                var date = new Date(element.lastUpdated);
                return date.toLocaleString();
            },
            filterable: true
        },{
        	header: "Modified By",
        	field: "modifiedBy",
        	filterable: false,
        	visible : false
    	}];
        if(Utils.isAdminUser()){
            columnCfgs.push({
                header: "Account",
                field: "acctId"
            });
        }
        columnCfgs.push({
            header: "ID",
            field: "id",
            defaultSort: true,
            filterable: true
    
        });
        this.columnCfgs = columnCfgs;
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }

    typeChange(){
        if(this.selectedType != "All"){
            this.customFilter.type = this.selectedType;
        }
        else{
            this.customFilter.type = undefined;
        }
        this.options.setItem(AESRemoteFunctionBlocks.REMOTE_FUNCTION_BLOCK_TYPE_FILTER, this.selectedType);
        this.dt.refresh(undefined, undefined);
    }
}
