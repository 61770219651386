import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmDialog, ViewRegistration } from "aes-common";
import { ColumnConfig } from "aes-datatables";
import { ButtonConfig, DataTableOptions } from "aes-datatables";
import { Utils } from "../../../services/Utils";
import { AccountAPIKey, AESAccountAPIKeysService } from "../../../services/AESAccountAPIKeysService";
import { AccountAPIKeyEditDialog } from "../../../dialogs/accountapikeydialog/accountapikeydialog";

@Component({
    selector: 'accountapikeys',
    templateUrl: 'accountapikeys.html',
    styleUrls: ['./accountapikeys.scss']
})
export class AESAccountAPIKeys {
    static view = new ViewRegistration("Account API Keys", "/configurations/accountapikeys", "private_connectivity", [], function(){
        return !Utils.isAdminUser();
    });
    dtOptions:DataTableOptions = {pageSize : 100, showSelectionColumn: false, multiSelect: false, columnFiltering: true, filtering: false};

    columnCfgs: ColumnConfig[] = [{
        header: "API Key",
        field: "apikey",
        defaultSort: true
    },{
        header: "Account",
        field: "acctId",
        defaultSort: true,
        visible : Utils.isAdminUser()
    },{
        header: "Creation",
        title: "Creation",
        field: "creationDate",
        dataType: 'date',
        filterable: true,
        renderContent: function(col: ColumnConfig, element: AccountAPIKey){
            console.log()
            var date = new Date(element.creationDate);
            return date.toLocaleString();
        }
    }, {
        header: "APIKeyID",
        field: "apiKeyId",
        visible: false
    },{
        header: "Connection",
        title: "The Connection ID to use",
        field: "connectionId",
        visible: false
    }, {
        header: "Connection Type",
        title: "The Connection Type",
        field: "connectionType",
        visible: false
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESAccountAPIKeysService,  public snackBar: MatSnackBar) {
        var me = this;
        if(Utils.isAdminUser()){
            this.buttons = [ButtonConfig.createAddButton("Create API Key", function(dt, selected){
                const dialogRef = me.dialog.open(AccountAPIKeyEditDialog, {
                    width: '600px',
                    data: {}
                });
        
                dialogRef.afterClosed().subscribe(result => {
                    if (result == "SUCCESS") {
                        dt.refresh(dt, selected);
                    }
                });
            }), ButtonConfig.createEditButton("Edit API Key", function(dt, selected){
                const dialogRef = me.dialog.open(AccountAPIKeyEditDialog, {
                    width: '600px',
                    data: {
                        "instance": selected[0]
                    }
                });
        
                dialogRef.afterClosed().subscribe(result => {
                    if (result == "SUCCESS") {
                        dt.refresh(dt, selected);
                    }
                });
            })];
        }
        else{
            this.buttons = [            {
                icon: "add",
                selection: ButtonConfig.SelectionType.NONE,
                title: "Create API Key",
                click: function(dt, selected){
                    dialog.open(ConfirmDialog, {
                        width: '600px',
                        data: {
                            "title" : "Create API Key",
                            "message" : "Are you sure you want to create an API Key?",
                            "success": function(){
                                me.httpClient.create({
                                    apikey : undefined,
                                    apiKeyId : undefined,
                                    creationDate : undefined,
                                    acctId : undefined,
                                })
                                .subscribe(resp => {
                                    me.openSnackBar("API key Created!", undefined);
                                    dt.refresh(dt, selected);
                                }, (err) => {
                                console.log(err);
                                var message = err.error.message;
                                if(message == undefined){
                                    message = "Unknown";
                                }
                                if(message == "Internal Server Error"){
                                    message = "Server was unable to process your request";
                                }
                                var error = "Error creating entry: " + message;
                                
                                me.openSnackBar(error, undefined);
                                dt.refresh(dt, selected);
                                });
                            }
                        }
                    });
                }
            }];
        }
        this.buttons.push(ButtonConfig.createDeleteButton("Delete API Key", function(selected){
            return "Are you sure you want to delete this API Key? Deleting it will remove access for AES APIs";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar("API key Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog));
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}
