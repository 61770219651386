<div class="container" fxLayout="column" [fxLayoutGap]="gapSize">
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100">
            <div card-title>
                Health Report
            </div>
            <div id="health-report-card-body" card-body>
                <div class="rightControls">
                    <mat-form-field>
                        <mat-select [disabled]="inProgress" placeholder="Account" (ngModelChange)="accountChanged($event)"
                        [(ngModel)]="selectedAccount" name="health-report-account-select" id="health-report-account-select">
                            <mat-option *ngFor="let account of accounts" [value]="account.acctId.toString()">
                                {{account.name}} ({{account.acctId}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select [disabled]="inProgress" placeholder="Interval" (ngModelChange)="intervalChanged($event)" 
                        [(ngModel)]="selectedInterval" name="health-report-interval-select" id="health-report-interval-select">
                            <mat-option *ngFor="let interval of intervals" [value]="interval.id">
                                {{interval.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>                    
                </div>
                <div id="deviceTypes" class="chart-with-large-legend-container">   
                    <div>Events By Device Type</div>
                    <canvas baseChart [type]="deviceTypeChartType" [data]="deviceTypeData" [options]="deviceTypeOptions"></canvas>
                </div>
                <div id="logCounts" class="chart-with-legend-container">   
                    <div>Log Entries By Type</div>
                    <canvas baseChart [type]="logCountChartType" [data]="logCountData" [options]="logCountOptions"></canvas>
                </div>                                                                         
            </div>
        </senetcard>
    </div>
</div>