import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface Pipeline {
    id: string;
    name: string;
    description: string;
    functionBlocks: any;
    config: any;
    lastUpdated: string;
    acctId: number;
    enabled: boolean;
}
@Injectable()
export class AESPipelinesService implements DataTableSearchService<Pipeline>, CRUDService<Pipeline>{

    functionBlockFilterId: string;

    constructor(private http: HttpClient) { }

    setFunctionBlockFilterId(functionBlockFilterId){
        this.functionBlockFilterId = functionBlockFilterId;
        //console.log("Setting Function Block ID: " + this.functionBlockFilterId);
    }
    create(object: Pipeline): Observable<Message> {
        const href = 'pipelines';
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object);
    }
    edit(object: Pipeline): Observable<Message> {
        const href = 'pipelines';
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object);
    }
    delete(object: Pipeline): Observable<Message> {
        const href ='pipelines';
        var params = new HttpParams().set("id", object.id);
        var requestUrl = `${href}`
        
        if(object.acctId != undefined){
            params = params.set("acctId", object.acctId.toString());
        }

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }

    get(id, acctId?): Observable<Pipeline> {
        const href = 'pipelines';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id.toString());
        }

        if(acctId != undefined){
            params = params.set("acctId", acctId.toString());
        }
        return this.http.get<Pipeline>(requestUrl, {
            params : params
        });
    }

    getPipelineConfig(): Observable<any>  {
        const href = 'pipelines/config';
        var requestUrl = `${href}`

        return this.http.get(requestUrl);
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any, columns: any): Observable<SenetData<Pipeline>> {
        const href = 'pipelines';
        
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();

        if(this.functionBlockFilterId){
            params = params.set("functionBlock", this.functionBlockFilterId);
        }
        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        }
        if(customFilter && customFilter.id != undefined){
            params = params.set("id", customFilter.id);
        }   

        if(customFilter && customFilter.acctId != undefined){
            params = params.set("acctId", customFilter.acctId);
        }
        return this.http.get<SenetData<Pipeline>>(requestUrl, {
            params : params
        });
    }
}