import { Component, ViewChild } from "@angular/core";
import { OptionsService, ViewRegistration } from "aes-common";
import { ColumnConfig, ColumnDataType, DataTable, DataTableOptions } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { LogConfigurationDialog } from "../../../dialogs/logconfigurationdialog/logconfigurationdialog";
import { AESLogConfigurationService, CustomLogConfigurationFilter, LogConfiguration } from "../../../services/AESLogConfigurationService";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Utils } from "../../../services/Utils";

@Component({
    selector: 'logconfiguration',
    templateUrl: 'logconfiguration.html',
    styleUrls: ['./logconfiguration.scss']
})
export class AESLogsConfiguration {
    static view = new ViewRegistration("Log Configuration", "/diagnostics/logconfig", "settings", []);

    dtOptions:DataTableOptions  = {pageSize : 100, columnFiltering: true, filtering: false, showSelectionColumn: false, multiSelect: false};
    @ViewChild(DataTable, { static: true })
    dt : DataTable

    columnCfgs: ColumnConfig[];

    buttons: ButtonConfig[];

    gapSize: string = "10px";
    customFilter : CustomLogConfigurationFilter;

    constructor(public dialog: MatDialog, public httpClient: AESLogConfigurationService,  public snackBar: MatSnackBar, public options : OptionsService) {
        var me = this;
        this.customFilter = {
        };
        this.buttons = [ButtonConfig.createAddButton("Create Log Configuration", function(dt, selected){
            const dialogRef = me.dialog.open(LogConfigurationDialog, {
                width: '600px',
                data: {},
                disableClose: true
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }), ButtonConfig.createEditButton("Edit Log Configuration", function(dt, selected){
            const dialogRef = me.dialog.open(LogConfigurationDialog, {
                width: '600px',
                data: {
                    "instance": selected[0]
                },
                disableClose: true
            });
    
            dialogRef.afterClosed().subscribe(result => {
                if (result == "SUCCESS") {
                    dt.refresh(dt, selected);
                }
            });
        }),ButtonConfig.createDeleteButton("Delete Log Configuration", function(selected){
            return "Are you sure you want to delete this Log Configuration?";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar("Log Configuration Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog)];

        var columnCfg : ColumnConfig[] = [{
            header: "ID",
            field: "id",
            visible: false
        },{
            header: "Expires",
            field: "expires",
            defaultSort: true,
            dataType: ColumnDataType.DATE,
            filterable: true,
            renderContent: function (col: ColumnConfig, element) {
                if(element.expires == undefined){
                    return "Never Expires";
                }
                let date = new Date(element.expires);
                return date.toLocaleString();
            }       
        },{
            header: "Created",
            field: "created",
            defaultSort: true,
            dataType: ColumnDataType.DATE,
            filterable: true,
            renderContent: function (col: ColumnConfig, element) {
                var date = new Date(element.created);
                return date.toLocaleString();
            }  
        },{
        	header: "Created By",
       		field: "createdBy",
       		filterable: false,
       		visible : false
        },{
            header: "Updated",
            field: "updated",
            defaultSort: true,
            dataType: ColumnDataType.DATE,
            filterable: true,
            renderContent: function (col: ColumnConfig, element) {
                var date = new Date(element.updated);
                return date.toLocaleString();
            } 
        },{
        	header: "Modified By",
       		field: "modifiedBy",
       		filterable: false,
       		visible : false     
        }, {
            header: "Configuration Field",
            field: "filterField",
            filterable: true
        }, {
            header: "Configuration Value",
            field: "filterValue",
        }, {
            header: "Log Level",
            field: "logLevel",
            renderContent: function (col: ColumnConfig, element) {
                let values = ["", "Error", "Warn", "Debug", "Trace", "All"]
                let rendered = values[element.logLevel];
                if(rendered == undefined){
                    rendered = "";
                }
                return rendered;
            }          
        }];
        if(Utils.isAdminUser()){
            columnCfg.push({
                header: "Account",
                field: "acctId"
            })
        }
        this.columnCfgs = columnCfg;
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}
