<div class="container" fxLayout="column" [fxLayoutGap]="gapSize" [ngClass]="{'hidden': !canShow}">
    <div align="end" class="edit-buttons">
        <button mat-button class="back-button" (click)="handleBack($event)">&lt;&nbsp;Back</button>
        <button class="edit-view-buttons" [disabled]="(inProgress || saveBlocked || !isDirty())" mat-button (click)="save()" cdkFocusInitial>Save</button>
        <button class="edit-view-buttons" mat-button (click)="cancel()">Cancel</button>
    </div>       
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">       
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100" [isEditDetailsCard]="true">            
            <div card-title>
                {{editType}} Pipeline{{titleSuffix}}                
            </div>            
            <div class="senet-card-body" card-body>
                <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
                <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChanged($event)">  
                    <mat-tab label="Information" class="pipeline-tab">
                        <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm" class="edit-tab-form info-tab-form">
                            <mat-form-field>
                                <input [disabled]="inProgress || editType == 'Edit'" matInput placeholder="ID" required [(ngModel)]="instance.id" name="pipeline-ID-field">
                            </mat-form-field>
                            <mat-form-field>
                                <input [disabled]="inProgress" matInput placeholder="Description" required [(ngModel)]="instance.description" name="pipeline-description-field">
                            </mat-form-field>
                            <mat-form-field *ngIf="isAdmin()">
                                <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" name="pipeline-acctId-field">
                                    <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                                        {{account.name}} ({{account.acctId}})
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-checkbox [disabled]="inProgress" *ngIf="isAdmin()" placeholder="Enabled" [(ngModel)]="instance.enabled"
                                                name="pipeline-enabled-edit">Enabled</mat-checkbox>
                        </form>
                        <div class="template-link-section">
                            <h3>Need help getting started? View a Pipeline example:</h3>
                            <ul>
                               <li class="template-link">
                                   <a target="_blank" href="{{templateExample.url}}">{{templateExample.label}}</a>
                               </li>
                           </ul>
                        </div>                        
                    </mat-tab>                        
                    <mat-tab label="Definition" class="pipeline-tab">
                        <form fxLayout="column" fxLayoutGap="10" #functionBlockForm="ngForm" class="edit-tab-form">
                            <senet-ace-editor #pipelineFbEditor (codeChange)="onCodeChanged('pipelineFbEditor')" [isForDialog]="false" [(code)]="instance.functionBlocksStr" [mode]="mode"></senet-ace-editor>
                        </form>
                    </mat-tab>
                    <!-- Formerly Configuration, Overrides are used to set Connection Configuration information.-->
                    <mat-tab *ngIf="false" label="Overrides" class="pipeline-tab">
                        <form fxLayout="column" fxLayoutGap="10" #configForm="ngForm" class="edit-tab-form">
                            <senet-ace-editor #pipelineConfigEditor  (codeChange)="onCodeChanged('pipelineConfigEditor')" [isForDialog]="false" [(code)]="instance.configStr"  [mode]="mode"></senet-ace-editor>
                        </form>
                    </mat-tab>  
                    <mat-tab label="Diagram" class="pipeline-tab">
                        <div *ngIf="instance.functionBlocks &&  instance.functionBlocks.length > 1" id="diagram" class="diagram-container"></div>
                        <div *ngIf="instance.functionBlocks && instance.functionBlocks.length == 1" class="diagram-container">
                            <a *ngIf="instance.functionBlocks[0].ID.indexOf('FunctionBlock') >= 0" class="single-function-block" href="/configurations/functionblocks/details/{{instance.functionBlocks[0].ID.replace('FunctionBlock ', '')}}">{{instance.functionBlocks[0].ID.replace('FunctionBlock ', '')}}</a>
                            <text *ngIf="instance.functionBlocks[0].ID.indexOf('FunctionBlock') < 0" class="single-function-block">{{instance.functionBlocks[0].ID.replace('FunctionBlock ', '')}}</text>
                        </div>
                    </mat-tab>                                       
                </mat-tab-group>
            </div>
        </senetcard>
    </div>
</div>
