<h2 mat-dialog-title>{{editType}} SMTP Connection</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm">
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Host" required [(ngModel)]="instance.host" name="connection-edit-host-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="User" [(ngModel)]="instance.username" name="connection-edit-user-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" type="password" matInput placeholder="Password" [(ngModel)]="instance.password" name="connection-edit-password-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" matInput placeholder="Sender" [(ngModel)]="instance.sender" name="connection-edit-sender-field">
        </mat-form-field>
        <mat-form-field>
            <input [disabled]="inProgress" type="number" matInput placeholder="Port" [(ngModel)]="instance.port" name="connection-edit-port-field">
        </mat-form-field>
        <mat-form-field>
            <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" name="connection-edit-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}}<span *ngIf="account.acctId != -2"> ({{account.acctId}})</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>