<h2 mat-dialog-title>{{editType}} Account API Key</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm">
        <mat-form-field *ngIf="editType == 'Create'">
            <mat-select [disabled]="inProgress" placeholder="Account" [(ngModel)]="instance.acctId" name="accountapikey-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}} ({{account.acctId}})
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="editType == 'Edit'">
            <input [disabled]="true" matInput placeholder="Account" value="{{selectedAccountName}} ({{instance.acctId}})" name="accountapikey-apikey-field">            
        </mat-form-field>         
            <mat-form-field *ngIf="editType == 'Edit'">
            <input [disabled]="true" matInput placeholder="API Key" [(ngModel)]="instance.apikey" name="accountapikey-apikey-field">
        </mat-form-field>        
        <mat-form-field *ngIf="editType == 'Edit'">
            <mat-select [disabled]="inProgress" placeholder="Connection" [(ngModel)]="instance.connectionId" name="accountapikey-edit-connection-field">
                <mat-option *ngFor="let connection of connections" [value]="connection.connectionId">
                    {{connection.host}}:{{connection.defaultDb}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>