<h2 mat-dialog-title>Log Message {{titleSuffix}}</h2>
<mat-dialog-content class="mat-typography">       
    <mat-tab-group #tabGroup>  
        <mat-tab label="Message" class="glu-tab">
            <form fxLayout="column" fxLayoutGap="10" #configForm="ngForm" class="edit-tab-form">
                <senet-ace-editor #logMessage  [(code)]="instance.msg" [isForDialog]="true" [mode]="mode"></senet-ace-editor>
            </form>
        </mat-tab>
        <mat-tab label="Detailed Message" class="glu-tab" *ngIf="instance.detailedMsg != undefined && instance.detailedMsg != '' && instance.detailedMsg !== null">
            <form fxLayout="column" fxLayoutGap="10" #configForm="ngForm" class="edit-tab-form">
                <senet-ace-editor #detailedLogMessage  [(code)]="instance.detailedMsg" [isForDialog]="true" [mode]="mode"></senet-ace-editor>
            </form>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>