<div class="container" fxLayout="column" [fxLayoutGap]="gapSize" [ngClass]="{'hidden': !canShow}">
    <div align="end" class="edit-buttons">
        <button mat-button class="back-button" (click)="handleBack($event)">&lt;&nbsp;Back</button>
        <button class="edit-view-buttons" [disabled]="(inProgress || saveBlocked || (isShared && !isAdmin()) || !isDirty())" mat-button (click)="save()" cdkFocusInitial>Save</button>
        <button class="edit-view-buttons" mat-button (click)="cancel()">Cancel</button>
    </div>     
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">       
        <senetcard class="edit-details-card" [fxFlex]="calculateSize(100)" fxFlex.xs="100" [isEditDetailsCard]="true">            
            <div card-title>
                {{editType}} Function Block{{titleSuffix}}                
            </div>            
            <div class="senet-card-body" card-body>
                <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
                <mat-tab-group #tabGroup (selectedTabChange)="selectedTabChanged($event)">  
                    <mat-tab label="Information" class="remotefunctionblock-tab">
                        <form fxLayout="column" fxLayoutGap="10" #infoForm="ngForm" class="edit-tab-form info-tab-form">
                            <mat-form-field>
                                <input [disabled]="inProgress || editType == 'Edit' || (isShared && !isAdmin())" matInput placeholder="ID" required [(ngModel)]="instance.id" name="remotefunctionblock-ID-field">
                            </mat-form-field>
                            <mat-form-field>
                                <input [disabled]="inProgress || (isShared && !isAdmin())" matInput placeholder="Name" required [(ngModel)]="instance.name" name="remotefunctionblock-name-field">
                            </mat-form-field>
                            <mat-form-field>
                                <input [disabled]="inProgress || (isShared && !isAdmin())" matInput placeholder="Description" [(ngModel)]="instance.description" name="remotefunctionblock-description-field">
                            </mat-form-field>
                            <mat-form-field *ngIf="isAdmin()">
                                <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" name="remotefunctionblock-acctId-field">
                                    <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                                        {{account.name}}<span *ngIf="account.acctId != -1"> ({{account.acctId}})</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-select [disabled]="inProgress || editType != 'Create' || (isShared && !isAdmin())" placeholder="Type" (ngModelChange)="handleTypeChange($event)" [(ngModel)]="instance.type" name="remotefunctionblock-type-field">
                                    <mat-option *ngFor="let type of types" [value]="type">
                                        {{type}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                         </form>
                         <div class="template-link-section">
                            <h3>Need help getting started? View our Function Block Templates:</h3>
                            <ul>
                                <li class="template-link" *ngFor="let templateExample of templateExamples">
                                    <a target="_blank" href="{{templateExample.url}}">{{templateExample.label}}</a>
                                </li>
                            </ul>  
                        </div>                       
                    </mat-tab>                          
                    <mat-tab label="Code" class="remotefunctionblock-tab">
                        <form fxLayout="column" fxLayoutGap="10" #codeForm="ngForm" class="edit-tab-form">
                        <senet-ace-editor #fbDetailsCodeEditor (codeChange)="onCodeChanged('fbCodeEditor')" [isForDialog]="false" [(code)]="instance.code" [mode]="mode"></senet-ace-editor>
                    </form>
                    </mat-tab>
                    <mat-tab label="Init" class="remotefunctionblock-tab">
                        <form fxLayout="column" fxLayoutGap="10" #initForm="ngForm" class="edit-tab-form">
                            <senet-ace-editor #fbDetailsInitEditor (codeChange)="onCodeChanged('fbInitEditor')" [isForDialog]="false" [(code)]="instance.init" [mode]="mode"></senet-ace-editor>
                        </form>
                    </mat-tab>
                    <mat-tab *ngIf="editType != 'Create'" label="Pipelines" class="remotefunctionblock-tab">
                        <function-block-pipelines-panel [functionBlockFilterId]="functionBlockFilterId"></function-block-pipelines-panel>
                    </mat-tab>                    
                </mat-tab-group>
            </div>
        </senetcard>
    </div>
</div>
