import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { SideNav } from 'aes-common';
import { GlobalScope } from 'aes-common';
import { OptionsService } from 'aes-common';
import { environment } from '../environments/environment';
import { Utils } from '../services/Utils';
import { UserService } from "../services/UserService";
import { AppStateService } from '../services/AppStateService';
import { EventsService, Event } from '../services/EventsService';
import { SenetData } from 'aes-datatables/public-api';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { HttpClient, HttpParams } from "@angular/common/http";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [GlobalScope]
})
export class AppComponent implements OnDestroy, OnInit {

  static PORTAL_TITLE = "PORTAL_TITLE";
  static DEFAULT_PORTAL_TITLE = "AES Portal";

  env = environment;
  opened: boolean;
  loggedIn: boolean;
  loading: boolean;
  @ViewChild(SideNav, { static: false }) sideNav: SideNav
  title = "AES Portal";

  events: any[];
  eventCount: number;
  eventsEnabled: boolean;
  autoRefresh: Subscription;
  username: string;
  logoutSub: Subscription;

  titleChangeSub: Subscription;
  times = 0;
  versionStr : any = undefined;
  versionTimer: any;
  loggedInTimer: any;

  version: string = environment.DEVICES_API_ENDPOINT;
  googleAPIKey: String = environment.googleAPIKey;
  enableAutoRefresh: boolean = environment.ENABLE_AUTO_REFRESH;
  constructor(private options: OptionsService, private userOptions: UserService, private appState: AppStateService, 
    public eventsService: EventsService, public titleService: Title, private cookieService: CookieService, 
    public snackBar: MatSnackBar, public router: Router, private http: HttpClient) {
    this.opened = options.getItem(OptionsService.SENET_SIDENAV_KEY, "true") == "true" ? true : false;
    Utils.DEVICES_API_KEY = this.options.getItem(Utils.SEN_SAVED_API_KEY_KEY, "");
    this.loggedIn = environment.LOGIN_DISABLED || (Utils.DEVICES_API_KEY != "" && environment.USE_API_KEY);
    this.events = [];
    if (!this.loggedIn) {
      this.titleService.setTitle(this.title);
    }
    Utils.DECIMAL_PRECISION = parseInt(this.options.getItem(Utils.SEN_DECIMAL_PRECISION_KEY, 2));
    var me = this;
    this.logoutSub = AppStateService.logout$.subscribe(() => {
      me.logout();
    });
    this.autoRefresh = GlobalScope.autoRefresh$.subscribe(() => {
      me.refreshEvents();
    });
    this.titleChangeSub = AppStateService.titleChange$.subscribe((newTitle) => {
      me.titleService.setTitle(newTitle);
      me.title = newTitle;
    });
    this.checkVersion = this.checkVersion.bind(this);
    this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
  }

  checkVersion(){
    var me = this;
    this.http.get<any>("./assets/versionInfo.json?v=" + this.times).subscribe(data =>{
      me.times += 1;
      if(me.versionStr == undefined){
        me.versionStr = data.version;
      }
      if(data.version != me.versionStr){
        console.log("New Version detected");
        window.location.reload();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.autoRefresh) {
      this.autoRefresh.unsubscribe();
    }
    if (this.logoutSub) {
      this.logoutSub.unsubscribe();
    }
    if (this.titleChangeSub) {
      this.titleChangeSub.unsubscribe();
    }
    if(this.versionTimer != undefined){
      clearInterval(this.versionTimer);
      this.versionTimer = undefined;
    }
    if(this.loggedInTimer != undefined){
      clearInterval(this.loggedInTimer);
      this.loggedInTimer = undefined;
    }
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.initUserInfo(() => { });
      this.refreshEvents();
    }
    this.checkVersion();
    this.versionTimer = setInterval(this.checkVersion, 60000);
  }

  refreshEvents() {
    var me = this;
    this.eventsService.getOpenEvents("lastOcurrence", "desc", 10).subscribe((res: SenetData<Event>) => {
      me.eventCount = res.totalRecords;
      me.events.length = 0;
      for (let event of res.data) {
        var dat = "Unknown";
        if (event.lastOccurrence != undefined) {
          var dateTm = new Date(event.lastOccurrence);
          if (!isNaN(dateTm.getTime()) && dateTm.getTime() != 0) {
            dat = Utils.relativeDate(dateTm);
          }
        }
        var str = event.information + "<br> <span class=\"event-last-occurrence\">Last Occurred: " + dat + "<span>";
        console.log(str);
        me.events.push({ "info": str });
      }
    });
  }

  checkIfLoggedIn(){
    this.userOptions.getUser(function (userObj) {});
  }

  initUserInfo(cb) {
    var me = this;
    let callsToMake = 3;
    this.loading = true;
    this.userOptions.getUser(function (userObj) {
      Utils.AES_USER_OBJ = userObj;
      if (userObj && userObj.username) {
        me.username = userObj.username;
      }
      else if (userObj && userObj.name) {
        me.username = userObj.name;
      }
      let completeInit = function(){
          var nextView = me.router.url;
          if (nextView != undefined && nextView != "") {
            var viewUrl = nextView;
            if (viewUrl.indexOf("?") >= 0) {
              viewUrl = viewUrl.substring(0, viewUrl.indexOf("?"))
            }
            if (viewUrl.startsWith("/")) {
              viewUrl = viewUrl.substring(1);
            }
            for (let views of routes) {
              if (viewUrl == views.path) {
                //@ts-ignore
                if (views.component.view && !views.component.view.isViewVisible()) {
                  nextView = "/";
                  me.options.setItem(OptionsService.SENET_LASTVIEW_KEY, "");
                }
              }
            }
          }
          if (nextView != me.router.url) {
            if(Utils.isAdminUser()){
              me.router.navigateByUrl("admin/accounts");
            }
            else{
              me.router.navigateByUrl("");
            }
          }
          me.loading = false;
          cb();
      }
      me.userOptions.getAccountFromServer().subscribe((acctObj) => {
        var defaults = GlobalScope.DEFAULT_HIDDEN_VIEWS;
        Utils.AES_ACCOUNT_OBJ = acctObj;
        if(Utils.isAdminUser()){
          defaults = "";
        }
        me.userOptions.getAccountItem(Utils.SEN_HIDDEN_VIEWS_KEY, defaults, (resp)=>{
          callsToMake--;
          if(resp && resp.split){
              GlobalScope.setHiddenViews(resp.split(','));
              me.eventsEnabled = !GlobalScope.isViewHidden('Event History') && !GlobalScope.isViewHidden('Events');
          }else{
              console.log("Unable to get hidden view list from server.");
              GlobalScope.setHiddenViews([]);
          }
          if(callsToMake == 0){
            completeInit();
          }
          });
          me.userOptions.getItem(Utils.SEN_ACE_TAB_SPACES_KEY, 4, (resp)=>{
            callsToMake--;
            if(resp && !isNaN(+resp)){
              me.options.setItem(OptionsService.SENET_ACE_TAB_SPACES_KEY, resp);
            }else{
                console.log("Unable to get editor tab spaces value from server.");
            }
            if(callsToMake == 0){
              completeInit();
            }            
          });
          me.userOptions.getItem(Utils.SEN_ACE_TABS_AS_SPACES_KEY, true, (resp)=>{
            callsToMake--;
            if(resp && !isNaN(+resp)){
               me.options.setItem(OptionsService.SENET_ACE_TABS_AS_SPACES_KEY, resp);
            }else{
                console.log("Unable to get editor tabs as spaces value from server.");
            }
            if(callsToMake == 0){
              completeInit();
            }            
          }); 

      }, (error) => {
        me.openSnackBar("Unable to get user account: " + error.error.message, "");
      })
    });
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'right'
    });
  }

  getPageTitle() {
    var me = this;
    setTimeout(function () {
      me.userOptions.getItem(AppComponent.PORTAL_TITLE, AppComponent.DEFAULT_PORTAL_TITLE, function (prop) {
        me.titleService.setTitle(prop);
        me.title = prop;
        Utils.APPLICATION_TITLE = prop;
      });
    }, 1000);
  }

  login(apiKey) {
    var me = this;
    this.initUserInfo(() => {
      me.loggedIn = true;
      me.loggedInTimer = setInterval(me.checkIfLoggedIn, 60000);
      if (apiKey) {
        Utils.DEVICES_API_KEY = apiKey;
        me.options.setItem(Utils.SEN_SAVED_API_KEY_KEY, apiKey);
      }
    });
    this.refreshEvents();
  }

  logout() {
    if(this.loggedInTimer != undefined){
      clearInterval(this.loggedInTimer);
      this.loggedInTimer = undefined;
    }
    if (Utils.DEVICES_API_KEY != undefined && Utils.DEVICES_API_KEY != "") {
      this.loggedIn = false;
      this.options.setItem(Utils.SEN_SAVED_API_KEY_KEY, "");
      Utils.DEVICES_API_KEY = "";
      window.location.reload();
    }
    else {
      var me = this;
      this.userOptions.logout().subscribe(() => {
        me.loggedIn = false;
        Utils.setAuthToken(me.options, "");
        window.location.reload();
      }, (err) => {
        me.loggedIn = false;
        Utils.setAuthToken(me.options, "");
        window.location.reload();
      });
    }
  }

  registerView(view) {
    this.sideNav.registerView(view);
  }

  toggleOpened() {
    this.opened = !this.opened;
    this.options.setItem(OptionsService.SENET_SIDENAV_KEY, this.opened);
  }
}
