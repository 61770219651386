<h2 mat-dialog-title>{{editType}} User API Key</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #deviceForm="ngForm">
        <mat-form-field>
            <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Username" [(ngModel)]="instance.username" name="userapikey-edit-username-field">
                <mat-option *ngFor="let user of users" [value]="user.username">
                    {{user.username}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="editType == 'Edit'">
            <input [disabled]="true" matInput placeholder="API Key" required [(ngModel)]="instance.apikey" name="userapikey-apikey-field">
        </mat-form-field>            
        <mat-form-field *ngIf="editType == 'Edit'">
            <mat-select [disabled]="inProgress" placeholder="Connection" [(ngModel)]="instance.connectionId" name="userapikey-edit-connection-field">
                <mat-option *ngFor="let connection of connections" [value]="connection.connectionId">
                    {{connection.host}}:{{connection.defaultDb}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>