<mat-toolbar color="accent">
    <span [ngClass]="{'item-padding': !config.hideSidenav, 'title-nonav': config.hideSidenav}">{{config.getTitle()}}</span>
    <button mat-icon-button (click)="toggleOpen()" [ngClass]="{'sidenav-hidden': config.hideSidenav}">
        <mat-icon aria-label="SideMenu" *ngIf="opened">chevron_left</mat-icon>
        <mat-icon aria-label="SideMenu" *ngIf="!opened">chevron_right</mat-icon>
    </button>
    <span class="break"></span>
    <span *ngFor="let view of views" class="details-button" [ngClass]="{'active': view.id == selection}" (click)="setView(view)"
        [matTooltip]="view.title" [matTooltipPosition]="'below'">
        <button *ngIf="!config.hideSidenav && view.isVisible" mat-icon-button>
            <mat-icon>{{view.icon}}</mat-icon>
        </button>
        <button class="text-tab" color="accent" *ngIf="config.hideSidenav && view.isVisible">
            {{view.title}}
        </button>   
    </span>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav class="sidenav-main" mode="side" [opened]="opened" autosize [ngClass]="{'sidenav-hidden': config.hideSidenav}">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxFlex="50%">
                <ng-content select="detailsimg"></ng-content>
            </div>
            <mat-toolbar fxFlex="50px" color="accent" *ngIf="subviews != undefined && subviews.length > 1">
                <span *ngFor="let view of subviews" class="details-button" [ngClass]="{'active': view.id == sideselection}"
                    (click)="setSubView(view)" [matTooltip]="view.title" [matTooltipPosition]="'below'">
                    <button mat-icon-button>
                        <mat-icon>{{view.icon}}</mat-icon>
                    </button>
                </span>
            </mat-toolbar>
            <div fxFlex="50%">
                <div *ngIf="sideselection == infoView.getId()">
                    <ng-content select="detailsinfopnl"></ng-content>
                </div>
                <ng-content select="[subviews]"></ng-content>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <ng-content select="[views]"></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>