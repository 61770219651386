<div class="container" fxLayout="column" [fxLayoutGap]="gapSize" [ngClass]="{'hidden': !canShow}">
    <div align="end" class="edit-buttons">
        <button mat-button class="back-button" (click)="handleBack($event)">&lt;&nbsp;Back</button>
    </div>        
    <div fxLayout="row" fxLayout.xs="column" [fxLayoutGap]="gapSize">       
        <senetcard [fxFlex]="calculateSize(100)" fxFlex.xs="100" [isEditDetailsCard]="true">            
            <div card-title>
                Log{{titleSuffix}}                
            </div>            
            <div class="senet-card-body" card-body>
                <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
                <mat-tab-group #tabGroup>  
                    <mat-tab label="Message" class="glu-tab">
                        <form fxLayout="column" fxLayoutGap="10" #configForm="ngForm" class="edit-tab-form">
                            <senet-ace-editor #logMessage  [(code)]="instance.msg" [isForDialog]="false" [mode]="mode"></senet-ace-editor>
                        </form>
                    </mat-tab>
                    <mat-tab label="Detailed Message" class="glu-tab" [hidden]="instance.detailedMsg == undefined || instance.detailedMsg == '' || instance.detailedMsg == null">
                        <form fxLayout="column" fxLayoutGap="10" #configForm="ngForm" class="edit-tab-form">
                            <senet-ace-editor #detailedLogMessage  [(code)]="instance.detailedMsg" [isForDialog]="false" [mode]="mode"></senet-ace-editor>
                        </form>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </senetcard>
    </div>
</div>
