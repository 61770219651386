import { GlobalScope } from "../../services/GlobalScope";
import { DetailsNavView } from "./details-nav-view/details-nav-view";

export class DetailsViewReg {
    id: string;
    title: string;
    icon: string;
    view: DetailsNavView;
    isVisible : boolean;

    constructor(id, title, icon){
        this.id = id;
        this.title = title;
        this.icon = icon;
        this.isVisible = !GlobalScope.isViewHidden(this.title);
        GlobalScope.addRegisteredView(this.title);
    }

    public isViewVisible(){
        if(this.isVisible != undefined && typeof this.isVisible === 'boolean'){
            return this.isVisible && !GlobalScope.isViewHidden(this.title);
        }
        else if(this.isVisible != undefined && typeof this.isVisible === 'function'){
            return this.isVisible && !GlobalScope.isViewHidden(this.title);
        }

        return this.isVisible == undefined && !GlobalScope.isViewHidden(this.title);
    }

    getId(){
        return this.id;
    }
}