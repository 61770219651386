import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { MapDataService } from "./MapDataService";
import { Message } from "aes-common";

export interface Device {
    devEui: string,
    lastUplinkTime: string,
    name: string,
    devType: string,
    dynamicState: any,
    configuredState : any,
    owner: string,
    deviceClass: string,
    dateCreated: Date,
    deviceLocation: string,
    tags: string,
    deviceProfile: number,
    deviceProfileName: string,
    application: string
}

export interface DeviceUpdate{
    devices: string[],
    name? : string,
    tags?: string,
    owner?: string,
    deviceProfile?: number,
    devType? : string
}
@Injectable()
export class AESDevicesService implements DataTableSearchService<Device>, MapDataService{

    devEui : string;

    constructor(private http: HttpClient) { }

    setDeviceEui(eui: string) {
        this.devEui = eui;
    }

    get(devEui, fields?: any, start?: any, end?: any, search? : string, columns?: any): Observable<SenetData<Device>> {
        const href = 'devices';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(devEui != undefined){
            params = params.set("device", devEui);
        }
        if(fields != undefined){
            params = params.set("fields", fields);
        }
        if(start != undefined){
            params = params.set("start", start);
        }
        if(end != undefined){
            params = params.set("end", end);
        }
        if(search != undefined){
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        }           
        return this.http.get<SenetData<Device>>(requestUrl, { params : params });
    }

    getDeviceCommands(devEui?: string, appEui?): Observable<any> {
        const href = 'devices/codecs/commands';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(devEui != undefined){
            params = params.set("device", devEui);
        }
        if(appEui){
            params = params.set("appEui", appEui);
        }
        return this.http.get<any>(requestUrl, { params : params });
    }

    sendDeviceCommands(device, command, appEui?): Observable<any> {
        const href = 'devices/codecs/commands';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(device != undefined){
            params = params.set("device", device);
        }
        if(appEui){
            params = params.set("appEui", appEui);
        }
        return this.http.post<any>(requestUrl, command, { params : params });
    }

    searchMap(sort: string, order: string, page: number, search: string, limit: number, fields: string): Observable<SenetData<Device>> {
        const href = 'devices';
        var offset = page * limit;
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(sort != undefined){
            params = params.set("sortField", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortOrder", order);
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        
        if(fields != undefined){
            params = params.set("fields", fields);
        }
        return this.http.get<SenetData<Device>>(requestUrl, { params : params });
    }

    edit(object: DeviceUpdate): Observable<Message> {
        var href = "devices";
        var params = new HttpParams();
        return this.http.post<Message>(href, object, { params : params });
    }

    delete(objects: Device[]): Observable<Message> {
        const href ='devices';
        var body = {
            devices: objects
        };
        var requestUrl = `${href}`

        return this.http.delete<Message>(requestUrl, {
            "body" : body
        });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any, columns: any): Observable<SenetData<Device>> {
        const href = 'devices';
        var offset = page * limit;
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(sort != undefined){
            params = params.set("sortField", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortOrder", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        }
        return this.http.get<SenetData<Device>>(requestUrl, { params : params });
    }
}