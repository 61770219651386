import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class OptionsService {
    static SENET_STORAGE_PREFIX = "SENET_";
    static SENET_EXPAND_KEY = "SEN_EXPAND";
    static SENET_SIDENAV_KEY = "SENET_SIDENAV";
    static SENET_LASTVIEW_KEY = "SEN_LASTVIEW";
    static DETAILS_PREFIX = "SEN_DETAILS";
    static SENET_AUTO_REFRESH = "SENET_AUTO_REFRESH";
    static SENET_AUTO_REFRESH_INTERVAL = "SENET_AUTO_REFRESH_INTERVAL";
    static SENET_TABLE_ID_PREFIX = "SENET_TABLE_ID_PREFIX_";
    static SENET_ACE_TAB_SPACES_KEY = "SENET_ACE_TAB_SPACES";
    static SENET_ACE_TABS_AS_SPACES_KEY = "SENET_ACE_TABS_AS_SPACES";

    constructor() {
    }

    getItem(id, defaultVal){
        var val = localStorage.getItem(id);
        if(val == undefined){
            return defaultVal;
        }
        return val;
    }

    setItem(id, value){
        if(value != undefined){
            value = value.toString();
        }
        else{
            value = "";
        }
        localStorage.setItem(id, value);
    }
    removeItem(id){
        if(id != undefined){
            localStorage.removeItem(id);
        }
     }
    parseBoolean(val){
        if(typeof val == 'boolean'){
            return val;
        }
        if(typeof val == 'string'){
            val = val.toLowerCase();
            return val == "true";
        }
        else val != undefined;
    }
}