<h2 mat-dialog-title>{{editType}} Log Configuration</h2>
<mat-dialog-content class="mat-typography">
    <p class="error" ng-if="error != '' && error != undefined">{{error}}</p>
    <form fxLayout="column" fxLayoutGap="10" #infoForm="ngForm" class="logconfig-edit-form">
        <mat-form-field>
            <input matInput [matDatepicker]="picker"[formControl]="expires" placeholder="Expires" [disabled]="inProgress || (isShared && !isAdmin())">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker" class="date-toggle"  [disabled]="inProgress || (isShared && !isAdmin())">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker  [disabled]="inProgress || (isShared && !isAdmin())"></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin()">
            <mat-select [disabled]="inProgress || (isShared && !isAdmin())" placeholder="Diagnostic Field" [(ngModel)]="instance.filterField" name="logconfig-diag-field">
                <mat-option value="pipelineId">
                    Pipeline
                </mat-option>
                <mat-option value="device">
                    Device
                </mat-option>
                <mat-option value="application">
                    Application
                </mat-option>
                <mat-option value="functionBlock">
                    Function Block
                </mat-option>
                <mat-option value="caller">
                    Logger Owner (caller)
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!isAdmin()">
            <mat-select [disabled]="inProgress || isShared" placeholder="Diagnostic Field" [(ngModel)]="instance.filterField" name="logconfig-diag-field">
                <mat-option value="pipelineId">
                    Pipeline
                </mat-option>
                <mat-option value="device">
                    Device
                </mat-option>
            </mat-select>
        </mat-form-field>        
        <mat-form-field >
            <input [disabled]="inProgress || isShared" matInput placeholder="Diagnostic Value" required [(ngModel)]="instance.filterValue" name="logconfig-value-field">
        </mat-form-field>
        <mat-form-field>
            <mat-select placeholder="Log Level" [(ngModel)]="instance.logLevelStr" name="logconfig-level-field" [disabled]="inProgress || (isShared && !isAdmin())">
                <mat-option value="1">
                    Error
                </mat-option>
                <mat-option value="2">
                    Warn
                </mat-option>
                <mat-option value="3">
                    Debug
                </mat-option>
                <mat-option value="4">
                    Trace
                </mat-option>
                <mat-option value="5">
                    All
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin()">
            <mat-select [disabled]="inProgress || editType != 'Create'" placeholder="Account" [(ngModel)]="instance.acctId" name="logconfig-acctId-field">
                <mat-option *ngFor="let account of accounts" [value]="account.acctId">
                    {{account.name}}<span *ngIf="account.acctId != -1"> ({{account.acctId}})</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button [disabled]="inProgress || saveBlocked || (isShared && !isAdmin())" mat-button (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>