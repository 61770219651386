import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AESExportService{
 
    constructor(private http: HttpClient) { }

    exportPipelinesAndFunctionBlocks(): Observable<any>{
        const href = 'export';
        let requestUrl = `${href}`;
        return this.http.get(requestUrl); 
    }
}