import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateFn } from '@angular/router';
import { ConfirmDialog} from 'aes-common';
import { Observable } from 'rxjs';

export interface ComponentNeedsSave {
    isDirty?: () => Observable<boolean> | Promise<boolean> | boolean;
  }

export const canDeactivateGuard: CanDeactivateFn<ComponentNeedsSave> = (component: ComponentNeedsSave) => {
    let dialog = inject(MatDialog);
    let needsSave = component.isDirty ? component.isDirty() : false;
    if(needsSave == true){
        let dialogRef = dialog.open(ConfirmDialog, {
            width: '600px',
            data: {
                "title" : "Unsaved Changes",
                "message" : "There are unsaved changes, are you sure you want to leave?",
                "success": function(){
                    return true;
                }
            }
        });
        return dialogRef.afterClosed();
    }
    return true;
};
    