import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AESReportService{
    public static COLORS_RGBA = [
        'rgba(21,86,134,.5)', 'rgba(73,138,64,.5)', 'rgba(223,154,31,.5)', 'rgba(229,203,96,.5)', 
        'rgba(109,152,191,.5)', 'rgba(130,130,130,.5)', 'rgba(58,150,148,.5)', 'rgba(203,224,240,.5)', 
        'rgba(0,102,178,.5)', 'rgba(53,117,25,.5)', 'rgba(178,114,0,.5)', 'rgba(178,143,0,.5)', 
        'rgba(0,93,178,.5)', 'rgba(76,76,76,.5)', 'rgba(0,178,174,.5)', 'rgba(0,101,178,.5)', 
        'rgba(25,113,178,.5)', 'rgba(44,178,25,.5)', 'rgba(178,123,25,.5)', 'rgba(178,148,25,.5)', 
        'rgba(25,105,178,.5)', 'rgba(102,102,102,.5)', 'rgba(25,178,175,.5)', 'rgba(25,112,178,.5)', 
        'rgba(51,124,178,.5)', 'rgba(66,178,51,.5)', 'rgba(178,132,51,.5)', 'rgba(178,153,51,.5)', 
        'rgba(51,117,178,.5)', 'rgba(127,127,127,.5)', 'rgba(51,178,175,.5)', 'rgba(51,123,178,.5)', 
        'rgba(76,135,178,.5)', 'rgba(88,178,76,.5)', 'rgba(178,141,76,.5)', 'rgba(178,158,76,.5)', 
        'rgba(76,129,178,.5)', 'rgba(153,153,153,.5)', 'rgba(76,178,176,.5)', 'rgba(76,134,178,.5)', 
        'rgba(102,146,178,.5)', 'rgba(111,178,102,.5)', 'rgba(178,151,102,.5)', 'rgba(178,163,102,.5)', 
        'rgba(102,142,178,.5)', 'rgba(178,178,178,.5)', 'rgba(102,178,176,.5)', 'rgba(102,145,178,.5)'
    ];  
    public static LOG_LEVEL_COLORS = {'ERROR':'rgba(183, 14, 31,.5)', 'WARN':'rgba(223,154,31,.5)', 
        'FOO':'rgba(229,203,96,.5)', 'INFO': 'rgba(109,152,191,.5)',
        'DEBUG':'rgba(73,138,64,.5)', 'TRACE':'rgba(130,130,130,.5)'};
    constructor(private http: HttpClient) { }

    getMinuteFloorTimeAsString(numMinutes){
        var minutes = 1000 * 60 * numMinutes;//5 is minutes
        var date = new Date();
        var minuteFloorTime = new Date(Math.floor(date.getTime() / minutes) * minutes).toISOString().replace('T', ' ').replace('Z', '');
        return "'" + minuteFloorTime + "'";
    }
    getMinuteFloorTimeAsMilliseconds(numMinutes){
        let minutes = 1000 * 60 * numMinutes;//5 is minutes
        let date = new Date();
        let nowIso = date.toISOString().replace('T', ' ').replace('Z', '');
        let floorDate = new Date(Math.floor(date.getTime() / minutes) * minutes);
        let floorDateIso = floorDate.toISOString().replace('T', ' ').replace('Z', '');
        var minuteFloorTime = floorDate.getTime();
     
        //console.log("getMinuteFloorTimeAsMilliseconds - " + " nowTime: " + nowIso + " minuteFloorTime: " + floorDateIso );
        return minuteFloorTime;
    }
    generateLabelArray(hours){
        let minuteFloorTime = this.getMinuteFloorTimeAsMilliseconds(5);
        let labels = [minuteFloorTime];
        let numMinuteIntervals = hours * 12;
        for(let i = 0; i <= numMinuteIntervals; i++){
            let time = minuteFloorTime - (i * 300000);//5 minutes in milliseconds
            let timeIso = new Date(time).toISOString().replace('T', ' ').replace('Z', '');
            //console.log("generateLabelArray - time: " + timeIso);
            labels.push(time);
        }
        return labels.reverse();
    }
    generateChartMinMaxForHours(hours){
        let minuteFloorTime = this.getMinuteFloorTimeAsMilliseconds(5);
        let min = minuteFloorTime - (hours * 3600000);
        let max = minuteFloorTime;
        return {min: min, max: max};
    }
    getDeviceUplinks(): Observable<any>{
        const href = 'devices/events/aggregations';
        let requestUrl = `${href}`;
        //var params = {start:"2015-01-07T12:30:47.395Z", limit: 5000};
        let params = new HttpParams();
        params.set('start', "2015-01-07T12:30:47.395Z");
        params.set('limit', 5000);
        let body = {"aggregation":{"field":"txtime","operation":"COUNT"},"groupBy":{"field": "txtime", "fieldType":"TIME", "interval": 86400}};
        return this.http.post<any>(requestUrl, body, { params : params });      
    }   
     
    getHealthReportData(accountId: number, start: string, end: string, limit: number, interval: string): Observable<any>{
        const href = 'reports';
        let requestUrl = `${href}?id=HealthReport`;
        if(accountId != undefined && accountId > 0){
            requestUrl += `&accountId=${accountId}`;
        }
        if(start != undefined && start != ''){
            requestUrl += `&start=${start}`;
        }
        if(end != undefined && end != ''){
            requestUrl += `&end=${end}`;
        }
        if(limit != undefined && limit > 0){
            requestUrl += `&limit=${limit}`;
        }
        if(interval != undefined && interval != ''){
            requestUrl += `&interval=${interval}`;
        }
        /*
        This method doesn't work for some reason.
        let params = new HttpParams();
        params.set("id", "HealthReport");
        params.set('limit', 500);
        */
        return this.http.get<any>(requestUrl); 
    }
    getData(start: string, end: string, limit: number, interval: string): Observable<any> {
        throw new Error("Method not implemented.");
    }
}