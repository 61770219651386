import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AfterViewInit } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { js_beautify } from 'js-beautify';
import { SenetAceEditor } from "aes-common";

@Component({
    selector: 'logdialog',
    templateUrl: 'log-dialog.html',
    styleUrls: ['./log-dialog.css']
})
export class LogDialog implements AfterViewInit {
    @ViewChild('logMessage', {static: false}) logMessage: SenetAceEditor;
    @ViewChild('detailedLogMessage', {static: false}) detailedLogMessage: SenetAceEditor;    
    instance: any;
    mode: string = "ace/mode/text";
    gapSize: string = "10px";
    error: string;
    title: string = "Log Entry";
    titleSuffix: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public titleService: Title, public dialogRef: MatDialogRef<LogDialog>) {
        var inst = data.instance;
        if (inst != undefined) {
            this.instance = {...inst};
            
            if(this.instance.time != undefined && this.instance.time != ""){
                var date = new Date(this.instance.time);
                date.toLocaleString();
                this.titleSuffix = " - " + date.toLocaleString();
            }
            this.titleService.setTitle(this.title + this.titleSuffix);//Overridden if defined in app-routing module.ts
            var options = { indent_size: 2, space_in_empty_paren: true };
            try{
                if(typeof this.instance.msg == "object" && this.instance.msg !== null){
                    this.instance.msg = JSON.stringify(this.instance.msg);
                }
                this.instance.msg = js_beautify(this.instance.msg, options);
            }
            catch(e){};
            try{
                if(typeof this.instance.detailedMsg == "object" && this.instance.detailedMsg !== null){
                    this.instance.detailedMsg = JSON.stringify(this.instance.detailedMsg);
                }
                this.instance.detailedMsg = js_beautify(this.instance.detailedMsg, options);
            }
            catch(e){};
        }
        else{
            this.instance = {};
        }
        var me = this;        
    }
    
    ngAfterViewInit(){
        var options = { indent_size: 2, space_in_empty_paren: true };
        this.instance.msg = js_beautify(this.instance.msg, options);
        this.logMessage.aceEditor.setReadOnly(true);
        this.logMessage.aceEditor.getSession().setUseWorker(false);
        this.detailedLogMessage.aceEditor.setReadOnly(true);
        this.detailedLogMessage.aceEditor.getSession().setUseWorker(false); 
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }        
} 