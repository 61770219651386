import { Component, Input, HostListener } from "@angular/core";
import { Inject, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AfterViewInit } from "@angular/core";
import { js_beautify } from 'js-beautify';
import { SenetAceEditor } from "aes-common";

@Component({
    template: '<div *ngIf="element.value != undefined && element.value.constructor === ({}).constructor; else plainText">'
    + '<a (click)="showDialog($event, element)">{{renderValue(element.value)}}</a>'
    + '</div>'
    + '<ng-template #plainText>{{element.value}}</ng-template>'
})
export class JSONDialogLink{
    @Input()
    element: any;

    dialogRef: any;
    
    renderValue(value){
        if(value != undefined && value.constructor === ({}).constructor){
            let truncated = JSON.stringify(value).substring(0, 64);
            if(truncated.length == 64){
                truncated += "...";
            }
            return truncated;
        }
        else if(value == undefined){
            return "";
        }
        return value;
    }
    @HostListener("click")showDialog(evt, element){
        evt.stopPropagation();
        if(!this.dialogRef){

            this.dialogRef = this.dialog.open(JSONDialog, {
                width: '600px',
                data: {
                    "instance": element
                }
            });
    
            this.dialogRef.afterClosed().subscribe(result => {
                this.dialogRef = undefined;
            });            
            console.log("Called click handler");
        }
    }
    constructor(public dialog: MatDialog, private router: Router){
    }    
}

@Component({
    selector: 'jsondialog',
    templateUrl: 'jsondialog.html',
    styleUrls: ['./jsondialog.scss']
})
export class JSONDialog implements AfterViewInit {
    @ViewChild('jsonContent', {static: true}) jsonContent: SenetAceEditor;
    instance: any;
    mode: string = "ace/mode/json";
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<JSONDialog>) {
        var inst = data.instance;
        if (inst != undefined) {
            this.instance = {...inst};
            try{
                this.instance.value = JSON.stringify(this.instance.value);
            }catch(err){
                this.instance.value = data.instance.value;
            }

        }
        else{
            this.instance = {};
        }
        var me = this;        
    }
    
    ngAfterViewInit(){
        var options = { indent_size: 2, space_in_empty_paren: true };
        this.instance.value = js_beautify(this.instance.value, options);
        this.jsonContent.aceEditor.setReadOnly(true);
        this.jsonContent.aceEditor.getSession().setUseWorker(false);
    }   
}