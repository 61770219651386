import { Component, Input, ViewChild, Output, EventEmitter, AfterViewInit, OnDestroy, HostListener, ApplicationRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnConfig, ColumnDataType } from "./columncfg";
import { ButtonConfig } from "./buttoncfg";
import { OptionsService } from "aes-common";
import { of as observableOf, merge, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SenetData } from "./senetdata";
import { DataTableSearchService } from "./tablesearchservice";
import { TableSettingsDialog } from "./dialogs/tablesettings/tablesettings";
import { ColumnFilterDialog } from "./dialogs/columnfilters/columnfilters";
import { GlobalScope } from "aes-common";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { SelectionModel } from '@angular/cdk/collections';
const HEIGHT_NOT_TABLE = 150;
const DATA_TABLE_KEY = "DATA_TABLE";
const SORT_KEY = "SORT";
const SORT_DIR_KEY = "SORT_DIR";
const FILTER_KEY = "FILTER";
const COL_FILTER_KEY = "COL_FILTER";
const MILLIS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MILLIS_IN_MINUTE = SECONDS_IN_MINUTE * MILLIS_IN_SECOND;
const MINUTES_IN_HOUR = 60; 
const MILLIS_IN_HOUR = MINUTES_IN_HOUR * MILLIS_IN_MINUTE;
const HOURS_IN_DAY = 24;
const MILLIS_IN_DAY = HOURS_IN_DAY * MILLIS_IN_HOUR;

export interface DataTableOptions{
    pageSize?: number
    filtering?:boolean
    columnFiltering?:boolean
    sorting?:boolean
    localSearch?:boolean
    localSort?:boolean
    multiSelect?:boolean
    absoluteHeight?: boolean
    showSelectionColumn?: boolean
}

export interface DataTableSelectionEvent{
    dt: DataTable,
    selected: boolean,
    row: any
}

@Component({
    selector: 'datatable',
    templateUrl: 'datatable.html',
    styleUrls: ['./datatable.css']
})
export class DataTable implements AfterViewInit, OnDestroy {
    @HostListener('document:keydown', ['$event'])
    handleKeyDownEvent(event: KeyboardEvent) { 
        if(event.key == 'Shift'){
            this.shiftKeyDown = true;
        }
    }
    @HostListener('document:keyup', ['$event'])
    handleKeyUpEvent(event: KeyboardEvent) { 
        if(event.key == 'Shift'){
            this.shiftKeyDown = false;
        }
    }
    static DEFAULT_PAGE_SIZE: number = 25;
    @Input()
    public tableId: string;

    @Input()
    public columnCfg: ColumnConfig[] = [];

    @Input()
    public buttonCfg: ButtonConfig[] = [];

    @Input()
    public dtOptions: DataTableOptions = {
        pageSize: DataTable.DEFAULT_PAGE_SIZE,
        filtering: true,
        sorting: true,
        showSelectionColumn: true
    };

    @Input()
    public tableHeight: number;

    @Input()
    public data: any[];

    @Input()
    public searchService: DataTableSearchService<any>;

    @Input()
    public customFilter: any;

    @Input()
    public columnFilters: any;
    
    @Output() filter: EventEmitter<any> = new EventEmitter();

    @Output() selected: EventEmitter<DataTableSelectionEvent> = new EventEmitter();

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    viewCols: string[];
    ogDataSrc: any[];
    dataSource: MatTableDataSource<SenetData<any>> = new MatTableDataSource<SenetData<any>>();

    selection = new SelectionModel<any>(true, []);
    isLoadingResults = false;
    resultsLength: number;

    sub: any;
    pageSub: any;
    tableHeightRen: number;
    filterVal: string;
    colFilterBtnCfg: ButtonConfig;
    sortSet: boolean;

    autoRefresh: Subscription;
    firstSelectedRow: number;
    shiftKeyDown: boolean;

    constructor(private options: OptionsService, public snackBar: MatSnackBar, 
        public dialog: MatDialog, private router: Router, private route: ActivatedRoute,
        private appRef: ApplicationRef) {
        this.refresh = this.refresh.bind(this);
        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.openColumnFilterDialog = this.openColumnFilterDialog.bind(this);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    clickButton(button: ButtonConfig) {
        button.click(this, this.selection.selected);
    }

    isButtonDisabled(button: ButtonConfig) {
        var status = false;
        if (button.selection == ButtonConfig.SelectionType.NONE) {
            status = false;
        }
        else if (button.selection == ButtonConfig.SelectionType.SINGLE) {
            status = this.selection.selected.length != 1;
        }
        else if (button.selection == ButtonConfig.SelectionType.MULTI) {
            status = this.selection.selected.length == 0;
        }
        if(!status && button.selectionCondition != undefined){
            status = button.selectionCondition(this, this.selection.selected);
        }
        return status;
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if(!this.dtOptions.multiSelect){
            return;
        }
        var allSelected = this.isAllSelected() 
        allSelected ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
        if(allSelected){
            this.selected.emit({"dt" : this, "row": undefined, "selected": false});
        }
        else{
            this.selected.emit({"dt" : this, "row": undefined, "selected": true});
        }
    }

    private handleControlClick(row, rowIndex){
        this.selection.toggle(row); //Ctrl-click adds to or subtracts from any existing selection
        //TODO: Is this event processed by anything? Button enable is handled by evaluating internal selection model.
        //This overloads the existing selection event to define row as an array of rows not a single row object. 
        this.selected.emit({"dt" : this, "row": this.selection, "selected": this.selection.isSelected(row)});
    }
    private handleUnmodifiedClick(row, rowIndex){
        this.selection.clear(); //single click clears any existing selection
        this.selection.toggle(row);
        if(this.selection.isSelected(row)){
            this.firstSelectedRow = rowIndex;
        }else{
            this.firstSelectedRow = undefined;
        }
        this.selected.emit({"dt" : this, "row": row, "selected": this.selection.isSelected(row)});
    }    
    toggleSelection(event, row, rowIndex){
        /*
        if(!this.dtOptions.multiSelect && !this.selection.isSelected(row)){
            this.dataSource.data.forEach(r => this.selection.deselect(r));
        }
        this.selection.toggle(row);
        this.selected.emit({"dt" : this, "row": row, "selected": this.selection.isSelected(row)});
        */
        if (event.ctrlKey || event.metaKey) {
            this.shiftKeyDown = false;
            console.log("Control Key Down :" + event.ctrlKey + " Meta key down: " + event.metaKey);
            this.handleControlClick(row, rowIndex); // This records this.lastSelected
        } else if (event.shiftKey) {
            console.log("Shift key is down");
            this.handleShiftClick(rowIndex); 
        } else {
            this.shiftKeyDown = false;
            console.log("Click called.");
            this.handleUnmodifiedClick(row, rowIndex); // As does this call.
        }       
    }

    handleShiftClick(rowIndex) {
        const indexA = this.firstSelectedRow;
        const indexB = rowIndex;
        if (indexA > indexB) {
            // Descending order
            this.selectRowsBetween(indexB, indexA);
        } else {
            // Ascending order
            this.selectRowsBetween(indexA, indexB);
        }
    }

    private selectRowsBetween(start, end) {
        let currentIndex = 0;
        this.dataSource.data.forEach(row => {
            if (currentIndex >= start && currentIndex <= end) {
                this.selection.select(row);
            }
            currentIndex++;
        });
    }
    searchData(){
        if(this.filterVal == undefined || this.filterVal == ""){
            this.dataSource.data = this.ogDataSrc;
            this.resultsLength = this.dataSource.data.length;
            return;
        }
        var data = [];
        for(var key in this.ogDataSrc){
            for(var k in this.ogDataSrc[key]){
                var v = this.ogDataSrc[key][k];
                if(v != undefined && v.toString().indexOf(this.filterVal) >= 0){
                    data.push(this.ogDataSrc[key]);
                    break;
                }
            }
        }
        this.dataSource.data = data;
        this.resultsLength = this.dataSource.data.length;
    }

    sortData(){
        var me = this;
        var data = Array.prototype.slice.call(this.dataSource.data).sort(function(v1, v2){
            var f1 = v1[me.sort.active];
            var f2 = v2[me.sort.active];
            var dir = me.sort.direction;
            if(dir == undefined){
                dir = "desc";
            }
            if(typeof f1 == "string" && typeof f2 == "string"){
                return dir == "desc" ? f1.localeCompare(f2) : f2.localeCompare(f1);
            }
            else{
                if (f1 < f2) return dir == "desc" ? 1 : -1;
                if (f2 > f1) return dir == "desc" ? -1 : 1;
            }

            return 0;
        });
        this.dataSource.data = data;
    }

    applyFilter(event: KeyboardEvent, filterValue: string) {
        if (event.keyCode === 13) {
            if (this.dtOptions.filtering) {
                this.filter.emit({ filterValue });
                
                if(filterValue == ''){
                    filterValue = undefined;
                }
                this.options.setItem(this.createStorageKey(FILTER_KEY), filterValue);
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {"search": filterValue, "columns": JSON.stringify(this.columnFilters)},
                    replaceUrl: true,
                    queryParamsHandling: 'merge'

                });
                
                if(this.dtOptions.localSearch){
                    this.searchData();
                }
                else {
                    this.refresh(this, this.selection.selected);
                }
            }
        }
    }
    clearFilters(){
        this.filterVal = undefined;
        this.columnFilters = undefined;
        this.options.setItem(this.createStorageKey(FILTER_KEY), this.filterVal);
        this.options.setItem(this.createStorageKey(COL_FILTER_KEY), JSON.stringify(this.columnFilters));
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {"search": this.filterVal, "columns": JSON.stringify(this.columnFilters)},
            replaceUrl: true,
            queryParamsHandling: 'merge'

        });   
        this.refresh(this, this.selection.selected);     
    }
    convertValueToMillis(value, units){
        let converted = value;

        switch(units){
            case 'SECONDS':
                converted = value * MILLIS_IN_SECOND;
                break;
            case 'MINUTES':
                converted = value * MILLIS_IN_MINUTE;
                break;
            case 'HOURS':
                converted = value * MILLIS_IN_HOUR;
                break;
            case 'DAYS':
                converted = value * MILLIS_IN_DAY;
                break;
            default: 
                converted = value * MILLIS_IN_SECOND;
        }
        return converted;
    }
    convertRelativeTimeFilter(filter){
        let startMs = new Date().getTime() - this.convertValueToMillis(filter.start, filter.startUnits);
        let endMs =  new Date().getTime() - this.convertValueToMillis(filter.end, filter.endUnits);
        let convertedFilter = {start: startMs, end: endMs};
        console.log("StartMS: " + startMs + " EndMS: " + endMs);
        return convertedFilter;
    }
    convertAbsoluteTimeFilter(filter){
        let startMs = new Date(filter.startDate).getTime();
        let endMs =  new Date(filter.endDate).getTime();
        let convertedFilter = {start: startMs, end: endMs};
        console.log("StartMS: " + startMs + " EndMS: " + endMs);
        return convertedFilter;
    }
    createStorageKey(keySuffix){
        return this.tableId + "_" + DATA_TABLE_KEY + "_" + keySuffix;
    }
    openDialog() {
        const dialogRef = this.dialog.open(TableSettingsDialog, {
            data: {
                "columns": this.columnCfg,
                "viewColumns": this.viewCols
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != undefined && result != '' && result != null) {
                this.viewCols = this.dtOptions.showSelectionColumn ? ['select'] : [];
                if (result == "RESET") {
                    for (var key in this.columnCfg) {
                        var val = this.columnCfg[key];
                        if (val.visible || val.visible == undefined) {
                            this.viewCols.push(val.field);
                        }
                    }
                }
                else {
                    for (var key in result) {
                        var v = result[key];
                        if (v.checked) {
                            this.viewCols.push(v.field);
                        }
                    }
                }
            }
            this.options.setItem(OptionsService.SENET_TABLE_ID_PREFIX + this.tableId, JSON.stringify(this.viewCols));
        });
    }

    openColumnFilterDialog() {
        const dialogRef = this.dialog.open(ColumnFilterDialog, {
            data: {
                "dt": this,
                "columns": this.columnCfg,
                "viewColumns": this.viewCols,
                "columnFilters": this.columnFilters
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == undefined){
                return;
            }
            let changed = true;//JSON.stringify(this.columnFilters) != JSON.stringify(result); 
            if(Object.keys(result).length == 0){
                this.columnFilters = undefined;
            }else{
                this.columnFilters = result;
            }
            this.options.setItem(this.createStorageKey(COL_FILTER_KEY), JSON.stringify(this.columnFilters));
            
            if(this.filterVal == ''){
                this.filterVal = undefined;
            }
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {"search": this.filterVal, "columns": JSON.stringify(this.columnFilters)},
                replaceUrl: true,
                queryParamsHandling: 'merge'

            });
            
            if(changed){
                this.refresh(this, this.selection.selected);
            }
        });
    }

    getLength() {
        return this.resultsLength;
    }

    ngOnDestroy() {
        if (this.pageSub != undefined) {
            this.pageSub.unsubscribe();
        }
        if (this.sub != undefined) {
            this.sub.unsubscribe();
        }

        if(this.autoRefresh != undefined){
            this.autoRefresh.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.fixTableHeight();
        this.dataSource.sort = this.sort;
        var me = this;
        if(!this.data){
            this.pageSub = merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    startWith({}),
                    switchMap(() => {
                        setTimeout(() => me.isLoadingResults = true, 0);
                        let restFilters = me.getRestFilters();
                        return me.searchService!.search(
                            me.sort.active, me.sort.direction, me.paginator.pageIndex, me.filterVal, me.dtOptions.pageSize, me.customFilter, JSON.stringify(restFilters))
                    }),
                    map(data => {
                        // Flip flag to show that loading has finished.
                        setTimeout(() => me.isLoadingResults = false, 0);
                        me.resultsLength = data.totalRecords;

                        return data.data;
                    }),
                    catchError(() => {
                        setTimeout(() => me.isLoadingResults = false, 0);
                        me.openSnackBar("Unable to retrieve data", undefined);
                        me.dataSource.data = [];
                        me.ogDataSrc = [];
                        me.resultsLength = 0;
                        return observableOf([]);
                    })
                ).subscribe(data => {
                    me.selection.clear();
                    me.ogDataSrc = data;
                    me.dataSource.data = data;
                    if(this.dtOptions.localSearch){
                        this.searchData();
                    }
                    if(this.dtOptions.localSort){
                        this.sortData();
                    }
                });
        }
        this.sort.sortChange.subscribe(() => {
            me.paginator.pageIndex = 0;
            me.options.setItem(this.createStorageKey(SORT_KEY), this.sort.active);
            me.options.setItem(this.createStorageKey(SORT_DIR_KEY), this.sort.direction);
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {"sort": this.sort.active, "sortDir": this.sort.direction},
                replaceUrl: true,
                queryParamsHandling: 'merge'

            });
            if(this.dtOptions.localSort){
                this.sortData();
            }      
            if(this.dtOptions.localSearch){
                this.searchData();
            }      
        });
        this.autoRefresh = GlobalScope.autoRefresh$.subscribe(()=>{
            this.refresh(undefined, undefined);
        })        
    }

    renderElementContent(col, element) {
        var str = col.renderContent(col, element);
        return str;
    }

    defaultRender(col, element) {
        if (element != undefined && element[col.field] != undefined) {
            return element[col.field];
        }
        return "";
    }

    fixTableHeight() {
        if (!this.dtOptions.absoluteHeight) {
            if (this.tableHeight != undefined) {
                this.tableHeightRen = window.innerHeight - this.tableHeight;
            }
            else {
                this.tableHeightRen = window.innerHeight - HEIGHT_NOT_TABLE
            }
        }
        else{
            this.tableHeightRen = this.tableHeight;
        }
    }

    refresh(dt, selected) {
        this.paginator.pageIndex = 0;
        this.loadData();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    processData(data: SenetData<any>) {
        this.isLoadingResults = false;
        this.resultsLength = data.totalRecords;
        this.dataSource.data = data.data;
        this.ogDataSrc = data.data;
        if(this.dtOptions.localSearch){
            this.searchData();
        }
        if(this.dtOptions.localSort){
            this.sortData();
        }
        this.selection.clear()
        if (this.sub != undefined) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
    }
    getRestFilters(){
        let restFilters = undefined;
        for(var key in this.columnCfg){
            let colCfg = this.columnCfg[key];
            let filter = this.columnFilters && this.columnFilters.hasOwnProperty(colCfg.field) ? this.columnFilters[colCfg.field] : undefined;
            if(filter){
                if(!restFilters){
                    restFilters = {};
                }
                if(colCfg.dataType == ColumnDataType.DATE){
                    let convertedFilter = filter.datetimeType === 'Relative' ? this.convertRelativeTimeFilter(filter) : this.convertAbsoluteTimeFilter(filter);
                    restFilters[colCfg.field] = convertedFilter;
                }else{
                    restFilters[colCfg.field] = filter;
                }
            }
        }
        return restFilters;
    }
    clearOldDateTimeFilters(colFilters){
        let colKeys = Object.keys(colFilters);
        colKeys.forEach((key)=>{
            let colFilter = colFilters[key];
            let colCfg = this.columnCfg.find(item => item.field === key);
            if(colCfg.dataType === ColumnDataType.DATE && !colFilter.datetimeType){
                delete colFilters[key];
            }
            
        });
    }
    loadData() {
        if (this.sort.active == undefined || this.sort.direction == "") {
            return;
        }
        if (this.sub != undefined) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
        this.isLoadingResults = true;
        var me = this;
        let restFilters = this.getRestFilters();

        if(this.searchService){
            this.sub = this.searchService!.search(
                me.sort.active, me.sort.direction, me.paginator.pageIndex, me.filterVal, me.dtOptions.pageSize, me.customFilter, JSON.stringify(restFilters)).subscribe(data => {
                    me.processData(data);
                }, (err) => {
                    this.openSnackBar("Unable to retrieve data", undefined);
                    this.isLoadingResults = false;
                    this.dataSource.data = [];
                    this.ogDataSrc = [];
                    this.resultsLength = 0;
                });
        }else{
            this.isLoadingResults = false;
            this.searchData();
        }
    }

    ngOnChanges() {
        if(this.dtOptions.pageSize === undefined){
            this.dtOptions.pageSize = DataTable.DEFAULT_PAGE_SIZE;
        }
        if(this.dtOptions.filtering === undefined){
            this.dtOptions.filtering = true;
        }
        if(this.dtOptions.sorting === undefined){
            this.dtOptions.sorting = true;
        }
        if(this.dtOptions.showSelectionColumn === undefined){
            this.dtOptions.showSelectionColumn = true;
        }        
        this.fixTableHeight();
        this.viewCols = this.dtOptions.showSelectionColumn ? ['select'] : [];
        let sort = this.route.snapshot.queryParamMap.get('sort'); 
        let usedLocalStorage = false;
        var sortFound = false;
        var savedViewError = false;
        var savedView : string[] = JSON.parse(this.options.getItem(OptionsService.SENET_TABLE_ID_PREFIX + this.tableId, "[]"));
        let cfgColFields = this.columnCfg.map(a => a.field);
        //let columnCfgChanged = false;
        //let colCfgLength = this.dtOptions.showSelectionColumn ? this.columnCfg.length + 1 : this.columnCfg.length;
        if(!sort){
            sort = this.options.getItem(this.createStorageKey(SORT_KEY), undefined);
        }
        //Check to make sure saved view ID exists in current column configurations.
        var found = false;
        for(let view of savedView){
            if(cfgColFields.indexOf(view) == -1){
                savedViewError = true;
            }
        }
             
        for (var key in this.columnCfg) {
            var val = this.columnCfg[key];

            if(val.dataType == undefined){
                val.dataType = ColumnDataType.STRING;
            }
            if(val.field == sort){
                sortFound = true;
            }
        }
        if(savedViewError){
            savedView = [];
        }
        let sortDir = this.route.snapshot.queryParamMap.get('sortDir'); 
        if(!sortDir){
            sortDir = this.options.getItem(this.createStorageKey(SORT_DIR_KEY), undefined);
        }
        if(!sortFound){
            sort = undefined;
            sortDir = 'desc';
        }
        let savedColFilters = this.options.getItem(this.createStorageKey(COL_FILTER_KEY), undefined);
        let colFilters:any = this.route.snapshot.queryParamMap.get('columns');
        let savedFilter = this.options.getItem(this.createStorageKey(FILTER_KEY), undefined);
        let filter = undefined;
        let urlFilter = this.route.snapshot.queryParamMap.get('search');
        var defSort = sort;

        if(colFilters){
            colFilters = JSON.parse(colFilters);
        }else if(savedColFilters){
            colFilters = JSON.parse(savedColFilters)
            usedLocalStorage = true;
        }else{
            colFilters = {};
        }
        if(urlFilter){
            filter = urlFilter;
        }else if(savedFilter){
            filter = savedFilter;
            usedLocalStorage = true;
        }
        if(usedLocalStorage){
            this.clearOldDateTimeFilters(colFilters);
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {"search": filter, "columns": JSON.stringify(colFilters)},
                replaceUrl: true,
                queryParamsHandling: 'merge'

            });
        }
        if(Object.keys(colFilters).length > 0){
            this.columnFilters = colFilters;
        }
        if(savedView.length != 0){
            this.viewCols = savedView;
        }
        for (var key in this.columnCfg) {
            var val = this.columnCfg[key];
            if ((val.visible || val.visible == undefined) && savedView.length == 0) {
                this.viewCols.push(val.field);
            }
            if (val.defaultSort && sort == undefined) {
                defSort = val.field
            }
            if (val.renderContent == undefined) {
                val.renderContent = this.defaultRender;
            }
        }
        this.sortSet = sort != undefined;
        if (this.data != undefined) {
            if (this.pageSub != undefined) {
                this.pageSub.unsubscribe();
            }
            this.ogDataSrc = this.data;
            this.dataSource = new MatTableDataSource<SenetData<any>>(this.data);
        }
        if (this.data == undefined) {
            var found = false;
            for(var key in this.buttonCfg){
                var button = this.buttonCfg[key];
                if(button.title == "Refresh"){
                    found = true;
                    return;
                }
            }
            if(!found){
                this.buttonCfg.push({ //Refresh button
                    "icon": "replay",
                    "title": "Refresh",
                    "selection": ButtonConfig.SelectionType.NONE,
                    "click": this.refresh
                });
            }
        }
        var settingsFound = false, columnFilterFound = false;
        for(var key in this.buttonCfg){
            var button = this.buttonCfg[key];
            if(button.title == "Table Settings"){
                settingsFound = true;
                return;
            }
            if(button.title == "Column Filters"){
                columnFilterFound = true;
                return;
            }
        }
        if(!settingsFound){
            this.buttonCfg.push({ //Settings button
                "icon": "settings",
                "title": "Table Settings",
                "selection": ButtonConfig.SelectionType.NONE,
                "click": this.openDialog
            });
        }
        if(!columnFilterFound && this.dtOptions.columnFiltering){
            this.colFilterBtnCfg = { //Settings button
                "icon": "filter_alt",
                "title": "Column Filters",
                "selection": ButtonConfig.SelectionType.NONE,
                "click": this.openColumnFilterDialog
            };
        }        
        setTimeout(() => {
            this.filterVal = filter;
            if(this.data){
                this.resultsLength = this.data.length;
            }
            
            if(this.dtOptions.filtering){
                this.options.setItem(this.createStorageKey(FILTER_KEY), this.filterVal);
            }else{
                this.options.setItem(this.createStorageKey(FILTER_KEY), undefined);
            }
            
            if (filter != undefined) {
                this.filter.emit({ filter });
            }else{
                this.filterVal = undefined; 
            }
            if (defSort != undefined) {
                this.sort.sort(({ id: defSort, start: sortDir }) as MatSortable);
            }
            else {
                this.refresh(this, this.selection.selected);
            }
        }, 0);
    }
}