import { Component, Input, OnInit, Host } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DetailsNavView } from "aes-common";
import { ColumnConfig, DataTableOptions, DataTable } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import {Device } from "../../../../services/AESDevicesService";
import { MatSnackBar } from "@angular/material/snack-bar";
import { JSONDialogLink } from "src/dialogs/jsondialog/jsondialog";

@Component({
    selector: 'device-configuredstate-panel',
    templateUrl: 'devicedetails_configuredstate.html',
    styleUrls: ['./devicedetails_configuredstate.scss']
})
export class DeviceDetailsConfiguredStatePanel implements OnInit {

    @Input()
    public device: Device;

    parent: DetailsNavView;

    data: {key: string, value: any}[];

    dtOptions: DataTableOptions = { pageSize: 100, multiSelect: true, localSearch: true, localSort: true };

    columnCfgs: ColumnConfig[] = [{
        header: "Property",
        field: "key",
        defaultSort: true
    },{
        header: "Value",
        field: "value",
        contentComponent: JSONDialogLink       
    },{
        header: "Last Updated",
        field: "lastUpdated",
        renderContent: function (col: ColumnConfig, row) {
            var date = new Date(row.lastUpdated);
            return date.toLocaleString();
        }
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";
    tableHeight: number;

    constructor(public http: HttpClient,@Host() parent: DetailsNavView, public snackBar: MatSnackBar) {
        this.parent = parent;
        this.data = [];
    }

    ngOnInit(){
        console.log("onInit called for ConfiguredState")
        let properties = this.device['configuredState'];
        this.tableHeight = 190;
        for(const key in properties){
            let row = {key: key, value: properties[key]['value'], lastUpdated: properties[key]['updated']};
            this.data.push(row);
        }           
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }

}
