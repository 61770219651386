import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Account, AESAccountService } from "../../services/AESAccountService";
import { AESSMTPConnectionService } from "../../services/AESSMTPConnectionService";

@Component({
    selector: 'smtpconnectiondialog',
    templateUrl: 'smtpconnectiondialog.html',
    styleUrls: ['./smtpconnectiondialog.css']
})
export class SMTPConnectionEditDialog {

    instance: any;
    editType: string = "Create";

    inProgress: boolean;
    error: string;

    accounts: Account[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SMTPConnectionEditDialog>, private connectionSearch:AESSMTPConnectionService, private accountSearch: AESAccountService) {
        var inst = data.instance;
        if (inst != undefined) {
            this.instance = { ...inst };
            this.editType = "Edit";
        }
        else{
            this.instance = {
                "port" : 587
            };
        }
        var me = this;
        this.inProgress = true;
        accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
        .subscribe(data => {
            me.accounts = data.data;
            if(me.accounts == undefined || me.accounts.length == 0){
                me.error = "Unable to load accounts, please try again later or contact an administrator.";
            }
            else{
                me.accounts.splice(0, 0, {"name" : "None", "acctId" : -2, "connectionType" : "", "creationDate" : null, "desciption" : "", "defaultConnectionId": -1});
                me.inProgress = false;
                if(me.instance.acctId == undefined){
                    me.instance.acctId = data.data[0].acctId;
                }
            }
        }, (err) => {
           me.error = "Unable to load accounts, please try again later or contact an administrator.";
        });
    }

    save() {
        var obs;
        this.error = "";
        if(this.instance.username == ""){
            this.error = "A username is required";
            return;
        }
        if(this.instance.password == ""){
            this.error = "A password is required";
            return;
        }
        if(this.instance.host == undefined || this.instance.host == ''){
            this.error = "A host is required";
            return;
        }
        if(this.instance.acctId == -2){
            this.instance.acctId = null;
        }
        var me = this;
        if(this.editType == "Create"){
            obs = this.connectionSearch.create(this.instance);
        }
        else{
            obs = this.connectionSearch.edit(this.instance);
        }
        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           if(err && err.error && err.error.message){
            console.log(err);
            me.error = err.error.message;
            if(me.error == "Internal Server Error"){
                me.error = "Server was unable to process your request";
            }
        }
        else{
            me.error = "Unable to process request"
        }
           me.inProgress = false;
        });
    }
}