import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { AESLogConfigurationService } from "../../services/AESLogConfigurationService";
import { Utils } from "../../services/Utils";
import { AESAccountService, Account } from "../../services/AESAccountService";

@Component({
    selector: 'logconfigurationdialog',
    templateUrl: 'logconfigurationdialog.html',
    styleUrls: ['./logconfigurationdialog.css']
})
export class LogConfigurationDialog {
    MS_IN_DAY = 86400000;
    instance: any;
    editType: string = "Create";

    inProgress: boolean;
    saveBlocked: boolean;
    error: string;
    expires = new FormControl(new Date(new Date().getTime() + this.MS_IN_DAY));
    isShared : boolean = false;
    accounts : Account[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<LogConfigurationDialog>, public serve : AESLogConfigurationService, public accountSearch : AESAccountService) {
        var inst = data.instance;
        if (inst != undefined) {
            this.instance = {...inst};
            this.editType = "Edit";
            this.instance.logLevelStr = "" + this.instance.logLevel;
            if(this.instance.expires != undefined){
                this.expires.setValue(new Date(this.instance.expires))
            }
            this.isShared = this.instance.acctId == null;
        }
        else{
            this.instance = {};
            this.instance.logLevelStr = "1";
            if(!this.isAdmin()){
                this.instance.filterField = "device";
            }
        }
        if(this.isAdmin()){
            var me = this;
            accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.accounts = data.data;
                if(me.accounts == undefined || me.accounts.length == 0){
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                }
                else{
                  me.inProgress = false;
                  if(me.instance.acctId == undefined){
                        me.instance.acctId = data.data[0].acctId;
                    }
                }
            }, (err) => {
                me.error = "Unable to load accounts, please try again later or contact an administrator.";
            });            
        }
    }

    isAdmin(){
        return Utils.isAdminUser();
    }

    save() {
        this.error = "";
        var me = this;

        if(this.instance.filterField != undefined && this.instance.filterField != "" && (this.instance.filterValue == undefined || this.instance.filterValue == "")){
            this.error = "A Configuration Value is required";
            return;
        }
        this.instance.logLevel = parseInt(this.instance.logLevelStr);
        if(this.expires.value != undefined){
            this.instance.expires = this.expires.value.toISOString();
        }
        else{
            this.instance.expires = null;
        }
        if(this.instance.acctId == -1){//Clear account ID
            this.instance.acctId = null;
        }
        var obs;
        this.inProgress = true;
        if(this.editType == "Create"){
            obs = this.serve.create(this.instance);
        }
        else{
            obs = this.serve.edit(this.instance);
        }
        this.inProgress = true;
        obs.subscribe(resp => {
            me.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}