import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface Application {
    appEui: string;
    description: string;
    connectionId: number;
    connectionType: string;
    acctId: number;
}
@Injectable()
export class AESApplicationService implements DataTableSearchService<Application>, CRUDService<Application>{

    constructor(private http: HttpClient) { }

    create(object: Application): Observable<Message> {
        const href = 'applications';
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object);
    }
    edit(object: Application): Observable<Message> {
        const href = 'applications';
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object);
    }
    delete(object: Application): Observable<Message> {
        const href = 'applications';
        var params = new HttpParams().set("connectionId", object.connectionId.toString());
        var requestUrl = `${href}`

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any, columns: any): Observable<SenetData<Application>> {
        const href = 'applications';
        var offset = page * limit
        var params = new HttpParams().set("connectionType", "SQL");
        var requestUrl = `${href}`;
        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        } 
        return this.http.get<SenetData<Application>>(requestUrl, {
           params : params
        });
    }
}