import { Component, Input, ViewChild, OnInit, AfterViewInit, OnChanges } from "@angular/core";
import { ViewRegistration, OptionsService } from "aes-common";
import { ColumnConfig, SenetData } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../../services/UserService";
import { DeviceEventsService, DeviceEvent } from "../../services/AESDeviceEventsService";
import { Router } from "@angular/router";
import { AESDevicesService, Device } from "../../services/AESDevicesService";
import { MapDataService } from "../../services/MapDataService";
import { Utils } from "../../services/Utils";
import { MatSnackBar } from "@angular/material/snack-bar";
import {GoogleMap} from '@angular/google-maps';
import { Observable } from 'rxjs';
import { LnsDeviceLink } from "../devices/devices";
import { M } from "@angular/cdk/keycodes";

@Component({
    selector: 'aes-map',
    templateUrl: 'map.html',
    styleUrls: ['./map.scss']
})
export class AESMaps implements OnInit, AfterViewInit, OnChanges {
    static view = new ViewRegistration("Device Map", "/devices/map", "map", [], function(){
        return !Utils.isAdminUser();
    });

    static MAP_FILTER_KEY = "MAP_FILTER";
    static MAP_LABELS_ENABLED_KEY = "MAP_LABELS_ENABLED";
    static MAP_DEVICE_LIMIT_KEY = "MAP_DEVICE_LIMIT";
    static MAP_DEVICE_LIMIT_DEFAULT = 100;

    @Input()
    public device: Device;

    loading: boolean;

    gapSize: string = "10px";

    filter: string;

    labelsEnabled: boolean;

    iconConfig = "";

    showInfoPanel: boolean;

    details = [];

    selectedEvent: any;

    markerOptions: any;

    geocodeField: any;

    tileLoadedSub: Observable<void>;

    mapDeviceLimit: number = 100;

    mapDeviceLimitSet: boolean = false;

    httpClient : MapDataService; 
    events: SenetData<DeviceEvent>;
    numberTileLoaded: number = 0;

    lat: number = 39.8283;
    lng: number = -98.5795;

    @ViewChild('googleMap', { static: false }) googleMap !: GoogleMap;

    constructor(public dialog: MatDialog, public http: HttpClient, public snackBar: MatSnackBar, 
        public router: Router, public options : OptionsService, public devService: AESDevicesService, 
        public eventService : DeviceEventsService, public userService: UserService) {
        var me = this;
        this.refresh = this.refresh.bind(this);
        this.markerOptions = { 'icon' : this.iconConfig.toString()};
        this.resetView = this.resetView.bind(this);
        this.userService.getItem(Utils.SEN_GEOCODE_FIELD_KEY, undefined, (resp)=>{
            if(resp){
                this.geocodeField = resp;
            }
        });
        this.userService.getAccountItem(AESMaps.MAP_DEVICE_LIMIT_KEY, AESMaps.MAP_DEVICE_LIMIT_DEFAULT, (resp)=>{
            if(resp){
                this.mapDeviceLimit = resp;
                this.mapDeviceLimitSet = true;
                this.refresh(function(){
                    if(me.googleMap != undefined){
                     me.resetView();
                    }
                 });                
            }
        });        
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    onKey(event){
        var key = AESMaps.MAP_FILTER_KEY;
        if(this.device){
            key = key + "_" + this.device;
        }
        if(this.filter){
            this.options.setItem(key, this.filter);
        }
        else{
            this.options.setItem(key, "");
        }
        this.refresh();
    }

    ngOnInit(){
        var key = AESMaps.MAP_FILTER_KEY;
        if(this.device){
            key = key + "_" + this.device;
        }
        this.filter = this.options.getItem(key, "");
        this.labelsEnabled = this.options.parseBoolean(this.options.getItem(AESMaps.MAP_LABELS_ENABLED_KEY, false));     
    }

    toggleLabels(){
        this.labelsEnabled = !this.labelsEnabled;
        this.options.setItem(AESMaps.MAP_LABELS_ENABLED_KEY, this.labelsEnabled);
    }

    buildLabelOptions(text){
        return {
            color: 'black',
            background: "white",
            padding : '5px',
            "margin-top": '0px',
            fontFamily: '',
            fontSize: '14px',
            fontWeight: 'bold',
            text: text
        }
    }

    refresh(callback?){
        //this was getting called before these value was set making the limit always use the default value
        if( this.mapDeviceLimitSet){
            this.loading = true;
            this.showInfoPanel = false;
            var me = this;
            var timeField = "devEui";
            if(this.device){
                timeField = "txtime";
            }
            else{
                timeField = "lastUplinkTime";
            }
            if(this.events != undefined && this.events.data != undefined){
                this.events.data.length = 0;
            }       
            
            var fields = "devEui,deviceLocation";
            if (this.geocodeField != undefined){
				fields += ","+ this.geocodeField.substring(0, this.geocodeField.indexOf('.'));
			}
            
            this.httpClient.searchMap(timeField, "desc", 0, (this.filter? this.filter : ""), this.mapDeviceLimit, fields).subscribe( (res) =>{
                this.events = res;
                for(var key in this.events.data){
                    var event = this.events.data[key];
                    // @ts-ignore
                    event.label = this.buildLabelOptions(event.devEui);
                }
                me.loading = false;
                if(callback){
                    callback();
                }
            });            
        
        }
    }

    closeInfoPanelDlg(){
        this.showInfoPanel = false;
    }

    showInfoPanelDlg(selectedDevice){
        this.showInfoPanel = true;
        this.details.length = 0;
        this.selectedEvent = selectedDevice;
        if(this.device){
            this.details.push({
                label: "Time",
                value: this.convertDate(selectedDevice.txtime)
            });
        }
        else{
            this.details.push({
                label: "Last Uplink Time",
                value: this.convertDate(selectedDevice.lastUplinkTime)
            });
        }
        this.details.push({
            label: "Name",
            value: Utils.renderContent({
                header: "Name",
                field: "name",
            }, selectedDevice)
        });

        this.details.push({
            label: "Created",
            value: Utils.renderContent({
                header: "Created",
                field: "created",
            }, selectedDevice)
        });

        this.details.push({
            label: "Created",
            value: Utils.renderContent({
                header: "Created",
                field: "created",
            }, selectedDevice)
        });

        this.details.push({
            label: "Application",
            value: Utils.renderContent({
                header: "Application",
                field: "application",
            }, selectedDevice)
        });

        this.details.push({
            label: "Profile",
            value: Utils.renderContent({
                header: "Profile",
                field: "deviceProfile",
            }, selectedDevice)
        });

        this.details.push({
            label: "Location",
            value: Utils.renderContent({
                header: "Location",
                field: "deviceLocation",
            }, selectedDevice)
        });

        this.details.push({
            label: "Device Class",
            value: Utils.renderContent({
                header: "Device Class",
                field: "deviceClass",
            }, selectedDevice)
        });

        this.details.push({
            label: "Tags",
            value: Utils.renderContent({
                header: "Tags",
                field: "tags",
            }, selectedDevice)
        });
    }

    resetView(){
        // @ts-ignore
        var bounds: LatLngBounds = new google.maps.LatLngBounds();
        /*
        //Encompasses continental US
        let bounds = new google.maps.LatLngBounds(
            {lat: 16.387672681443753, lng: -158.69236298202696},  
            {lat: 57.80032206980902, lng: -36.17283173202697});
        */
        let boundsChanged = false;
        for (let event of this.events.data) {
            let position = this.getEventPosition(event);
            // @ts-ignore
            if(position.lat != undefined && position.lng != undefined){
                boundsChanged = true;
                bounds.extend(new google.maps.LatLng(position.lat, position.lng));
            }
            
        }
        if(boundsChanged){
            this.googleMap.fitBounds(bounds);
        }
    }

    ngOnChanges() {
        console.log(this.device);
        if(this.device){
            this.httpClient = this.eventService;
            this.httpClient.setDeviceEui(this.device.devEui);
        }
        else{
            this.httpClient = this.devService;
        }
        if(this.device != undefined){
            this.httpClient.setDeviceEui(this.device.devEui);
        }
        else{
            this.httpClient.setDeviceEui(undefined);
        }
        this.refresh();
    }

    ngAfterViewInit(){
        if(this.device){
            this.httpClient = this.eventService;
            this.httpClient.setDeviceEui(this.device.devEui);
        }
        else{
            this.httpClient = this.devService;
        }
        if(this.device != undefined){
            this.httpClient.setDeviceEui(this.device.devEui);
        }
        else{
            this.httpClient.setDeviceEui(undefined);
        }
        var me = this;
        this.refresh(function(){
           if(me.googleMap != undefined){
            me.resetView();
           }
        }); 
    }
    mapReady(map){
        if(this.googleMap){
            this.googleMap.zoomChanged.subscribe((e) => {console.log("bounds: " + this.googleMap.getBounds() + " zoom: " + this.googleMap.getZoom() + " center: " + this.googleMap.getCenter())});
        }
        if(this.events != undefined && this.numberTileLoaded == 0){
            this.resetView();
            this.numberTileLoaded += 1;
        }
    }

    navigateToDetails(dev, $event){
        console.log("here");
        var link = "/devices/details/" + dev
        if($event.ctrlKey){
            window.open(link, '_blank');
        }
        else {
            this.router.navigateByUrl(link).catch((e) => {
                console.log(e);
            });
        }
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }

    convertDate(d){
        return (new Date(d)).toLocaleString();
    }

    getUserLocation(obj:any, nestedKeys:string[]){
        let userLocationValue;
        let myObj = obj;
        nestedKeys.forEach((part)=>{
            if(myObj.hasOwnProperty(part) && myObj[part] !== null){
                userLocationValue = myObj[part].value;
                myObj = myObj[part];
            }
        })
        return userLocationValue;
    }
    hasLocation(event){
        let hasLocation = event.lat != undefined && event.lng != undefined && (event.lng != 0 || event.lat != 0);
        if(this.geocodeField){
            let nestedKeys = this.geocodeField.split(".");
            let userLocation = this.getUserLocation(event, nestedKeys);
            if(userLocation && typeof userLocation === 'string' && userLocation.indexOf(",")){
                let locArray = userLocation.split(",");
                if(locArray.length === 2){
                    let lat = Number.parseFloat(locArray[0]);
                    let lng = Number.parseFloat(locArray[1]);
                    hasLocation = lat != undefined && lng != undefined && (lat != 0 || lng != 0);
                }
            }
        }
        return hasLocation;        
    }
    getEventPosition(event){
        let position =  {'lat' : event.lat, 'lng' : event.lng};
        if(this.geocodeField){
            let nestedKeys = this.geocodeField.split(".");
            let userLocation = this.getUserLocation(event, nestedKeys);            
            if(userLocation && typeof userLocation === 'string' && userLocation.indexOf(",")){
                let locArray = userLocation.split(",");
                if(locArray.length === 2){
                    let lat = Number.parseFloat(locArray[0]);
                    let lng = Number.parseFloat(locArray[1]);
                    position =  {'lat' : lat, 'lng' : lng};
                }
            }
        }
        return position;
    }

}
