import { Component, Input, HostListener,  ViewChild, OnInit, OnDestroy, OnChanges} from "@angular/core";
import { ViewRegistration, ConfirmDialog, GlobalScope } from "aes-common";
import { ColumnConfig, DataTable, DataTableOptions, ColumnDataType } from "aes-datatables";
import { ButtonConfig } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from '@angular/router';
import { AESPipelinesService, Pipeline } from "../../services/AESPipelinesService";
import { Utils } from "../../services/Utils";
import { AESExportService } from "src/services/AESExportService";

const FUNCTION_BLOCK_HEIGHT = 260;

//Need to add class to app.module.ts if routerLink is used or there is a compile error.
@Component({
    template: "<a [routerLink]=\"!isAdmin() ? ['/configurations/pipelines/details', element.id] : ['/configurations/pipelines/details', element.id, element.acctId]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.description}}</a>"
})
export class PipelineDetailsLink {
    @Input()
    element: Pipeline;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    isAdmin(){
        return Utils.isAdminUser();
    }
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }    
}
@Component({
    template: "<a [routerLink]=\"!isAdmin() ? ['/configurations/pipelines/details', element.id] : ['/configurations/pipelines/details', element.id, element.acctId]\" [queryParams]=\"{'hideNav': hideNav, 'tab': 3}\">{{renderContent(element.functionBlocks)}}</a>"
})
export class ShowPipelineDiagramDialogCell{
    @Input()
    element: Pipeline;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    isAdmin(){
        return Utils.isAdminUser();
    }
    renderContent(functionBlocks){
        let functionBlocksIds = [];
        functionBlocks.forEach(function(functionBlock){
            let functionBlockId = functionBlock.ID;
            if(functionBlockId){
                functionBlockId = functionBlockId.replace(/FunctionBlock /g, '');
                functionBlocksIds.push(functionBlockId);
            }else{
                console.log(JSON.stringify(functionBlock));
            }
        });
        return functionBlocksIds.join(", ");
    }      
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }   
}

@Component({
    template: "<a [routerLink]=\"!isAdmin() ? ['/configurations/pipelines/details', element.id] : ['/configurations/pipelines/details', element.id, element.acctId]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.id}}</a>"
})
export class ShowPipelinesEditorCell{
    @Input()
    element: Pipeline;

    @Input()
    col: ColumnConfig;


    hideNav: boolean;
    constructor(public route: ActivatedRoute){}  
    isAdmin(){
        return Utils.isAdminUser();
    }
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }    
}

@Component({
    selector: 'pipelines',
    templateUrl: 'pipelines.html',
    styleUrls: ['./pipelines.scss']
})
export class AESPipelines implements OnInit, OnDestroy, OnChanges {
    @Input()
    functionBlockFilterId: string;

    static view = new ViewRegistration("Pipelines", "/configurations/pipelines", "input", []);
    
    dtOptions:DataTableOptions  = {pageSize : 100, sorting: false, columnFiltering: true, filtering: false, showSelectionColumn: false, multiSelect: false};

    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "id",
        defaultSort: true,
        contentComponent: ShowPipelinesEditorCell,
        filterable: true
    },{
        header: "Created By",
        field: "createdBy",
        filterable: false,
        visible : false
    },{
        header: "Description",
        field: "description",
        contentComponent: PipelineDetailsLink,
        filterable: true
    },{
        header: "Last Updated",
        field: "lastUpdated",
        dataType: ColumnDataType.DATE,
        renderContent: function(col: ColumnConfig, element: Pipeline){
            var date = new Date(element.lastUpdated);
            return date.toLocaleString();
        },
        filterable: true
    },{
        header: "Modified By",
        field: "modifiedBy",
        filterable: false,
        visible : false
    },{
        header: "Enabled",
        field: "enabled",
        renderContent: function(col: ColumnConfig, element: Pipeline){
            return element.enabled ? "True" : "False";
        },
        filterable: false,
        sortDisabled: true
    },{
        header: "Account",
        field: "acctId",
        filterable: Utils.isAdminUser(),
        visible : false
    },{
        header: "Function Block IDs",
        field: "functionBlocks",
        contentComponent: ShowPipelineDiagramDialogCell,
        renderContent: function(col: ColumnConfig, element: Pipeline){
            let functionBlocks = element.functionBlocks;
            let functionBlocksIds = [];
            functionBlocks.forEach(function(functionBlock){
                let functionBlockId = functionBlock.ID;
                if(functionBlockId){
                    functionBlockId = functionBlockId.replace(/FunctionBlock /g, '');
                    functionBlocksIds.push(functionBlockId);
                }else{
                    console.log(JSON.stringify(functionBlock));
                }
            });
            return functionBlocksIds.join(", ");
        }
     }];

    buttons: ButtonConfig[];

    tableHeight: number;
    gapSize: string = "10px";
    exportInProgress: boolean = false;

    @ViewChild(DataTable, { static: false })dt : DataTable;

    constructor(public dialog: MatDialog, public httpClient: AESPipelinesService,  public snackBar: MatSnackBar,
        private router: Router, public exportClient: AESExportService) {
        var me = this;

        this.buttons = [ButtonConfig.createAddButton("Create Pipeline", function(dt, selected){
            me.router.navigateByUrl("configurations/pipelines/details/-1");
        }), ButtonConfig.createEditButton("Edit Pipeline", function(dt, selected){
            if(Utils.isAdminUser()){
                me.router.navigateByUrl("configurations/pipelines/details/" + selected[0].id + "/" + selected[0].acctId);
            }
            else{
                me.router.navigateByUrl("configurations/pipelines/details/" + selected[0].id);
            }
        }), ButtonConfig.createDeleteButton("Delete Pipeline", function(selected){
            return "Are you sure you want to delete Pipeline " + selected.id + "?";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar(selected.id + " Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog)      
    ];
    if(!GlobalScope.isViewHidden('Pipeline Export')){
        this.buttons.push({
            icon: "download",
            selection: ButtonConfig.SelectionType.NONE,
            title: "Export Pipelines and Function Blocks",
            selectionCondition : function(dt, selected){
                return me.exportInProgress;
            },
            click: function(dt, selected){
                me.exportInProgress = true;
                dialog.open(ConfirmDialog, {
                    width: '600px',
                    data: {
                        "title" : "Export Pipelines and Function Blocks",
                        "message" : "Are you sure you want to export the Pipelines and Function Blocks?",
                        "success": function(){
                            me.exportClient.exportPipelinesAndFunctionBlocks()
                            .subscribe((resp) => {
                                if(resp.url){
                                    console.log(resp.url);
                                    var a = document.createElement("a");
                                    a.href = resp.url;
                                    // start download
                                    a.click();
                                    me.openSnackBar("Export complete.", undefined);
                                    me.exportInProgress = false;
                                }
                            }, (err) => {
                                me.showError(err);
                            });
                        }
                    }
                });
            }
        });         
    }
    }
    showError(err){
        this.exportInProgress = false;
        console.log(err);
        var message = err.error.message;
        if(message == undefined){
         message = "Unknown";
        }
        if(message == "Internal Server Error"){
         message = "Server was unable to process your request";
        }
        var error = "Error exporting Pipelines and Function Blocks: " + message;
        
        this.openSnackBar(error, undefined);
    }
    ngOnInit(){
        if(this.functionBlockFilterId != undefined){
            this.tableHeight = FUNCTION_BLOCK_HEIGHT;
            this.buttons = [];
            this.httpClient.setFunctionBlockFilterId(this.functionBlockFilterId);                
        }
        else{
            this.httpClient.setFunctionBlockFilterId(undefined);
        }         
    }
    ngOnChanges(){
        if(this.functionBlockFilterId != undefined){
            this.httpClient.setFunctionBlockFilterId(this.functionBlockFilterId);                
        }
        else{
            this.httpClient.setFunctionBlockFilterId(undefined);
        }        
    }
    ngAfterViewInit(){
        if(this.functionBlockFilterId != undefined){
            this.httpClient.setFunctionBlockFilterId(this.functionBlockFilterId);                
        }
        else{
            this.httpClient.setFunctionBlockFilterId(undefined);
        }          
    }
    ngOnDestroy(){
        this.httpClient.setFunctionBlockFilterId(undefined);
    }
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}
