import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AESAccountAPIKeysService } from "../../services/AESAccountAPIKeysService";
import { AESAccountService, Account } from "../../services/AESAccountService";
import { AESSQLConnectionService, SQLConnection } from "../../services/AESSQLConnectionService";


@Component({
    selector: 'accountapikeydialog',
    templateUrl: 'accountapikeydialog.html',
    styleUrls: ['./accountapikeydialog.css']
})
export class AccountAPIKeyEditDialog {

    instance: any;
    editType: string = "Create";

    inProgress: boolean;
    accounts: Account[];
    selectedAccountName: string;
    connections : SQLConnection[];
    connectionMap : any;
    error: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AccountAPIKeyEditDialog>, private userService:AESAccountAPIKeysService, private accountSearch: AESAccountService, private connectionSearch: AESSQLConnectionService) {
        var inst = data.instance;
        var me = this;
        if (inst != undefined) {
            this.instance = { ...inst };
            this.editType = "Edit";
            this.inProgress = true;
            this.instance.connectionId =  parseInt(this.instance.connectionId);
            accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.accounts = data.data;
                if(me.accounts == undefined || me.accounts.length == 0){
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                }
                else{
                    me.selectedAccountName = me.accounts.find(account => account.acctId === me.instance.acctId).name;
                    connectionSearch.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId})
                    .subscribe(data => {
                        me.connections = data.data;
                        me.connectionMap = {};
                        if(me.connections == undefined || me.connections.length == 0){
                            me.error = "Unable to load connections, please try again later or contact an administrator.";
                        }
                        else{
                            for(let connection of me.connections){
                                me.connectionMap["" + connection.connectionId] = connection;
                            }
                            if(me.instance.connectionId == undefined){
                                me.instance.connectionId = data.data[0].connectionId;
                            }
                            me.inProgress = false;
                        }
                    }, (err) => {
                        me.error = "Unable to load connections, please try again later or contact an administrator.";
                    });
                }
            }, (err) => {
                me.error = "Unable to load accounts, please try again later or contact an administrator.";
            });

        }
        else{
            this.instance = {};
            this.inProgress = true;
            accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.accounts = data.data;
                if(me.accounts == undefined || me.accounts.length == 0){
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                }
                else{
                    if(me.instance.acctId == undefined){
                        me.instance.acctId = data.data[0].acctId;
                    }
                    me.inProgress = false;
                }
            }, (err) => {
            me.error = "Unable to load accounts, please try again later or contact an administrator.";
            });
        }

    }

    save() {
        var obs;
        this.error = "";
        if(this.instance.acctId == undefined || this.instance.acctId == ''){
            this.error = "A acctId is required";
            return;
        }
        /*if((this.instance.password == undefined || this.instance.password == '') && this.editType == "Create"){
            this.error = "A password is required";
            return;
        }*/
        var me = this;

        if(this.editType == "Create"){
            obs = this.userService.create(this.instance);
        }
        else{
            obs = this.userService.edit(this.instance);
        }

        this.inProgress = true;
        obs.subscribe(resp => {
            this.dialogRef.close("SUCCESS");
            me.inProgress = false;
        }, (err) => {
           console.log(err);
           me.error = err.error.message;
           if(me.error == "Internal Server Error"){
               me.error = "Server was unable to process your request";
           }
           me.inProgress = false;
        });
    }
}