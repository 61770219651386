import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { MapDataService } from "./MapDataService";

export interface DeviceEvent {
    devEui: string,
    txtime: string,
    devType: string,
    dynamicState: any,
    configuredState : any,
    owner: string,
    deviceClass: string,
    dateCreated: Date,
    deviceLocation: string
}
@Injectable()
export class DeviceEventsService implements DataTableSearchService<DeviceEvent>, MapDataService{

    devEui : string;

    constructor(private http: HttpClient) { }

    setDeviceEui(eui: string) {
        this.devEui = eui;
        console.log(this.devEui);
    }

    get(devEui, fields?: any, start?: any, end?: any, search? : string, columns?:any): Observable<SenetData<DeviceEvent>> {
        const href = 'devices/events';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(devEui != undefined){
            params = params.set("devEui", devEui);
        }
        if(fields != undefined){
            params = params.set("fields", fields);
        }
        if(start != undefined){
            params = params.set("start", start);
        }
        if(end != undefined){
            params = params.set("end", end);
        }
        if(search != undefined){
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        }       
        return this.http.get<SenetData<DeviceEvent>>(requestUrl, { params : params });
    }

    searchMap(sort: string, order: string, page: number, search: string, limit: number, fields: string): Observable<SenetData<DeviceEvent>> {
        const href = 'devices/events';
        var offset = page * limit;
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(this.devEui != undefined){
            params = params.set("device", this.devEui);
        }
        if(sort != undefined){
            params = params.set("sortField", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortOrder", order);
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        
        if(fields != undefined){
            params = params.set("fields", fields);
        }
        return this.http.get<SenetData<DeviceEvent>>(requestUrl, { params : params });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any, columns: any): Observable<SenetData<DeviceEvent>> {
        const href = 'devices/events';
        var offset = page * limit;
        var requestUrl = `${href}`;
        var params = new HttpParams();
        console.log(this.devEui);
        if(this.devEui != undefined){
            params = params.set("device", this.devEui);
        }
        if(sort != undefined){
            params = params.set("sortField", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortOrder", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        }   
        return this.http.get<SenetData<DeviceEvent>>(requestUrl, { params : params });
    }
}