import { Component, ViewChild, HostListener } from "@angular/core";
import { ActivatedRoute, Router} from "@angular/router";
import {NgForm} from '@angular/forms';
import { AfterViewInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import { MatDialog } from "@angular/material/dialog";
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ConfirmDialog, SenetAceEditor, OptionsService } from "aes-common";
import { AESDeviceProfileService } from "../../../services/AESDeviceProfileService";
import { AESPipelinesService, Pipeline } from "../../../services/AESPipelinesService";
import { AESCodecService, Codec } from "../../../services/AESCodecService";
import { AESAccountService, Account } from "../../../services/AESAccountService"
import { Utils } from "../../../services/Utils";

@Component({
    selector: 'device-profile-details',
    templateUrl: 'device-profile-details.html',
    styleUrls: ['./device-profile-details.css']
})
export class DeviceProfileDetails implements AfterViewInit {
    @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;
    @ViewChild("infoForm", { static: false }) infoForm: NgForm;
    @ViewChild('deviceProfileDefDataEditor', {static: true}) deviceProfileDefDataEditor: SenetAceEditor;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) { 
        if((event.ctrlKey || event.metaKey) && event.key == 's'){
            event.preventDefault();
            let backOnSave = this.editType === "Create";
            this.save(backOnSave);
        }
    }

    instance: any;
    editType: string = "Create";
    changed: boolean = false;
    inProgress: boolean;
    saveBlocked: boolean;
    error: string;
    pipelines: Pipeline[];
    downlinkPipelines: Pipeline[];
    codecs : Codec[];    
    accounts: Account[];
    pipelinesMap: any;
    isShared: boolean;
    profileId: string;
    titleSuffix: string;
    title: string;
    canShow: boolean = false;
    selectedTab: number = 0;
    gapSize: string = "10px";
    private canGoBack: boolean;
    private oldInst: any;
    mode : string = "ace/mode/javascript";
    firstCodeChangeEvent: boolean = true;
    codeChanged: boolean = false;
    private replaceCount: number = 0;
    private devProfDataStorageKey: string;

    constructor(public dialog: MatDialog, public serve : AESDeviceProfileService, private route: ActivatedRoute, public pipelineServe : AESPipelinesService, 
        public codecServe : AESCodecService, public snackBar: MatSnackBar, public titleService: Title, 
        private router: Router, private location: Location, 
        public accountSearch : AESAccountService, public options : OptionsService) {

        let me = this;
        this.profileId = this.route.snapshot.params["id"];
        this.devProfDataStorageKey = OptionsService.SENET_STORAGE_PREFIX + "devprofiledata_" + this.profileId;
        this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
        this.instance = {};
        if(this.isAdmin()){
            this.inProgress = true;
            if(this.profileId == '-1'){
                this.instance.periodicPipelinePeriodMins = 1;
                accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
                .subscribe(data => {
                    me.accounts = data.data;
                    if(me.accounts == undefined || me.accounts.length == 0){
                        me.error = "Unable to load accounts, please try again later or contact an administrator.";
                    }
                    else{
                        if(me.instance.acctId == undefined){
                            me.instance.acctId = data.data[0].acctId;
                        }
                        pipelineServe.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId}, undefined)
                        .subscribe(data => {
                            me.pipelines = data.data;
                            me.downlinkPipelines = data.data.slice();
                            me.downlinkPipelines.splice(0, 0, {acctId: -2, config : {}, description: "", enabled : false, functionBlocks : {}, id : "None", lastUpdated : undefined, name : ""});
                            if(me.instance.downlinkPipelineId == undefined){
                                me.instance.downlinkPipelineId = "None";
                            }
                            me.pipelinesMap = {};
                            if(me.pipelines == undefined || me.pipelines.length == 0){
                                me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                                me.openSnackBar("Unable to load pipelines", undefined);
                                me.canShow = true;
                            }
                            else{
                                if(me.instance.periodicPipelineId == undefined){
                                    me.instance.periodicPipelineId = data.data[0].id;
                                }
        
                                if(me.instance.uplinkPipelineId == undefined){
                                    me.instance.uplinkPipelineId = data.data[0].id;
                                }
                                codecServe.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId})
                                .subscribe(data => {
                                    me.codecs = data.data;
                                    if(me.codecs == undefined || me.codecs.length == 0){
                                        me.error = "Unable to load codecs, please try again later or contact an administrator.";
                                        me.openSnackBar("Unable to load codecs", undefined);
                                    }
                                    else{
                                        me.inProgress = false;
                                        /* Uncomment if codecs enabled
                                        if(me.instance.codecId == undefined){
                                            me.instance.codecId = 0; //set to default codec
                                        }
                                        */
                                    }
                                    me.oldInst = {...me.instance};
                                    me.canShow = true;
                                }, (err) => {
                                    me.error = "Unable to load codecs, please try again later or contact an administrator.";
                                    me.canShow = true;
                                });        
                            }
                        }, (err) => {
                            me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                            me.canShow = true;
                        });
                    }
                }, (err) => {
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                    me.canShow = true;
                });            
            }else{
                this.serve.get(this.profileId).subscribe(res => {
                    me.instance = {...res};
                    me.editType = "Edit";
                    if(me.instance.periodicPipelinePeriod !== undefined){
                        me.instance.periodicPipelinePeriodMins = me.instance.periodicPipelinePeriod / 60000;
                    }
                    else{
                        me.instance.periodicPipelinePeriodMins = 1;
                    }
                    accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
                    .subscribe(data => {
                        me.accounts = data.data;
                        if(me.accounts == undefined || me.accounts.length == 0){
                            me.error = "Unable to load accounts, please try again later or contact an administrator.";
                            me.openSnackBar("Unable to load accounts", undefined);
                            me.canShow = true;
                        }
                        else{
                            if(me.instance.acctId == undefined){
                                me.instance.acctId = data.data[0].acctId;
                            }
                            pipelineServe.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId}, undefined)
                            .subscribe(data => {
                                me.pipelines = data.data;
                                me.downlinkPipelines = data.data.slice();
                                me.downlinkPipelines.splice(0, 0, {acctId: -2, config : {}, description: "", enabled : false, functionBlocks : {}, id : "None", lastUpdated : undefined, name : ""});
                                if(me.instance.downlinkPipelineId == undefined){
                                    me.instance.downlinkPipelineId = "None";
                                }
                                me.pipelinesMap = {};
                                if(me.pipelines == undefined || me.pipelines.length == 0){
                                    me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                                    me.openSnackBar("Unable to load pipelines", undefined);
                                    me.canShow = true;
                                }
                                else{
                                    if(me.instance.periodicPipelineId == undefined){
                                        me.instance.periodicPipelineId = data.data[0].id;
                                    }
            
                                    if(me.instance.uplinkPipelineId == undefined){
                                        me.instance.uplinkPipelineId = data.data[0].id;
                                    }
                                    codecServe.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : me.instance.acctId})
                                    .subscribe(data => {
                                        me.codecs = data.data;
                                        if(me.codecs == undefined || me.codecs.length == 0){
                                            me.error = "Unable to load codecs, please try again later or contact an administrator.";
                                            me.openSnackBar("Unable to load codecs", undefined);
                                        }
                                        else{
                                            me.inProgress = false;
                                            /* Uncomment if codecs enabled
                                            if(me.instance.codecId == undefined){
                                                me.instance.codecId = 0; //set to default codec
                                            }
                                            */
                                        }
                                        if(me.instance.defaultDeviceData != undefined){
                                            me.instance.defaultDeviceDataStr = JSON.stringify(me.instance.defaultDeviceData, null, 4);
                                        }
                                        else{
                                            me.instance.defaultDeviceDataStr = JSON.stringify({}, null, 4);
                                        }
                                        me.oldInst = {...me.instance};
                                        me.canShow = true;
                                    }, (err) => {
                                        me.error = "Unable to load codecs, please try again later or contact an administrator.";
                                        me.canShow = true;    
                                    });        
                                }
                            }, (err) => {
                                me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                                me.canShow = true;
                            });
                        }
                    }, (err) => {
                        me.error = "Unable to load accounts, please try again later or contact an administrator.";
                        me.openSnackBar("Unable to load accounts", undefined);
                        me.canShow = true;
                    });
                }, err =>{
                    me.error = "Unable to load profile, please try again later or contact an administrator.";
                    me.openSnackBar("Unable to load Profile: " + me.profileId, undefined);
                    me.canShow = true;
                }); 
            }
        }
        else{
            if(this.profileId == '-1'){
                this.instance.periodicPipelinePeriodMins = 1;
                pipelineServe.search(undefined, undefined, undefined, undefined, undefined, {}, undefined)
                .subscribe(data => {
                    me.pipelines = data.data;
                    me.downlinkPipelines = data.data.slice();
                    me.downlinkPipelines.splice(0, 0, {acctId: -2, config : {}, description: "", enabled : false, functionBlocks : {}, id : "None", lastUpdated : undefined, name : ""});
                    if(me.instance.downlinkPipelineId == undefined){
                        me.instance.downlinkPipelineId = "None";
                    }
                    me.pipelinesMap = {};
                    if(me.pipelines == undefined || me.pipelines.length == 0){
                        me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                        me.openSnackBar("Unable to load pipelines", undefined);
                        me.canShow = true;
                    }
                    else{
                        if(me.instance.periodicPipelineId == undefined){
                            me.instance.periodicPipelineId = data.data[0].id;
                        }
        
                        if(me.instance.uplinkPipelineId == undefined){
                            me.instance.uplinkPipelineId = data.data[0].id;
                        }
                        codecServe.search(undefined, undefined, undefined, undefined, undefined, {})
                        .subscribe(data => {
                            me.codecs = data.data;
                            if(me.codecs == undefined || me.codecs.length == 0){
                                me.error = "Unable to load codecs, please try again later or contact an administrator.";
                                me.openSnackBar("Unable to load codecs", undefined);
                            }
                            else{
                                me.inProgress = false;
                                if(me.instance.codecId == undefined){
                                    me.instance.codecId = 0; //set to default codec
                                }
                            }
                            me.titleService.setTitle(me.title);//Overridden if defined in app-routing module.ts                   
                            me.inProgress = false;
                            me.canShow = true;
                            if(me.instance.defaultDeviceData != undefined){
                                me.instance.defaultDeviceDataStr = JSON.stringify(me.instance.defaultDeviceData, null, 4);
                            }
                            else{
                                me.instance.defaultDeviceDataStr = JSON.stringify({}, null, 4);
                            }
                            me.oldInst = {...me.instance};
                        }, (err) => {
                            me.error = "Unable to load codecs, please try again later or contact an administrator.";
                            me.openSnackBar("Unable to load codecs", undefined);
                            me.canShow = true;
                        });        
                    }
                }, (err) => {
                    me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                    me.openSnackBar("Unable to load pipelines", undefined);
                    me.canShow = true;
                });              
            }else{
                this.inProgress = true;
                this.serve.get(this.profileId).subscribe(res => {
                    var inst = res;
                    if (inst != undefined) {
                        me.instance = {...inst};
                        me.editType = "Edit";
                        me.isShared = this.instance.acctId == null;
                        if(me.instance.periodicPipelinePeriod !== undefined){
                            me.instance.periodicPipelinePeriodMins = me.instance.periodicPipelinePeriod / 60000;
                        }
                        else{
                            me.instance.periodicPipelinePeriodMins = 1;
                        }
                    }
                    else{
                        me.instance.periodicPipelinePeriodMins = 1
                    }
                    if(me.instance.defaultDeviceData != undefined){
                        me.instance.defaultDeviceDataStr = JSON.stringify(me.instance.defaultDeviceData, null, 4);
                    }
                    else{
                        me.instance.defaultDeviceDataStr = JSON.stringify({}, null, 4);
                    }
                    pipelineServe.search(undefined, undefined, undefined, undefined, undefined, {}, undefined)
                    .subscribe(data => {
                        me.pipelines = data.data;
                        me.downlinkPipelines = data.data.slice();
                        me.downlinkPipelines.splice(0, 0, {acctId: -2, config : {}, description: "", enabled : false, functionBlocks : {}, id : "None", lastUpdated : undefined, name : ""});
                        if(me.instance.downlinkPipelineId == undefined){
                            me.instance.downlinkPipelineId = "None";
                        }
                        me.pipelinesMap = {};
                        if(me.pipelines == undefined || me.pipelines.length == 0){
                            me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                            me.openSnackBar("Unable to load pipelines", undefined);
                            me.canShow = true;
                        }
                        else{
                            if(me.instance.periodicPipelineId == undefined){
                                me.instance.periodicPipelineId = data.data[0].id;
                            }
            
                            if(me.instance.uplinkPipelineId == undefined){
                                me.instance.uplinkPipelineId = data.data[0].id;
                            }
                            //Remove from here if Codecs enabled
                            this.titleService.setTitle(this.title);//Overridden if defined in app-routing module.ts                   
                            this.oldInst = {...this.instance};
                            this.inProgress = false;
                            this.canShow = true;
                            /* Uncomment if Codecs enabled
                            codecServe.search(undefined, undefined, undefined, undefined, undefined, {})
                            .subscribe(data => {
                                me.codecs = data.data;
                                if(me.codecs == undefined || me.codecs.length == 0){
                                    me.error = "Unable to load codecs, please try again later or contact an administrator.";
                                    me.openSnackBar("Unable to load codecs", undefined);
                                }
                                else{
                                    me.inProgress = false;
                                    if(me.instance.codecId == undefined){
                                        me.instance.codecId = 0; //set to default codec
                                    }
                                }
                                me.titleService.setTitle(me.title);//Overridden if defined in app-routing module.ts                   
                                me.oldInst = {...me.instance};
                                me.inProgress = false;
                                me.canShow = true;
                            }, (err) => {
                            me.error = "Unable to load codecs, please try again later or contact an administrator.";
                            });   
                            */     
                        }
                    }, (err) => {
                        me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                        me.openSnackBar("Unable to load pipelines", undefined);
                        me.canShow = true;
                    });
                }, err =>{
                    me.openSnackBar("Unable to load Profile: " + me.profileId, undefined);
                    me.canShow = true;
                }); 
            }
        }
    }

    handleAccountChange(acctId) {
        var me = this;
        me.pipelineServe.search(undefined, undefined, undefined, undefined, undefined, { "acctId": acctId }, undefined)
            .subscribe(data => {
                me.pipelines = data.data;
                me.downlinkPipelines = data.data.slice();
                me.downlinkPipelines.splice(0, 0, {acctId: -2, config : {}, description: "", enabled : false, functionBlocks : {}, id : "None", lastUpdated : undefined, name : ""});
                me.instance.downlinkPipelineId = "None";
                me.pipelinesMap = {};
                if (me.pipelines == undefined || me.pipelines.length == 0) {
                    me.error = "Unable to load pipelines, please try again later or contact an administrator.";
                }
                else {
                    me.instance.periodicPipelineId = data.data[0].id;
                    me.instance.uplinkPipelineId = data.data[0].id;
                    me.codecServe.search(undefined, undefined, undefined, undefined, undefined, {"acctId" : acctId})
                        .subscribe(data => {
                            me.codecs = data.data;
                            if(me.codecs == undefined || me.codecs.length == 0){
                                me.error = "Unable to load codecs, please try again later or contact an administrator.";
                            }
                            else{
                                me.inProgress = false;
                                if(me.instance.codecId == undefined || me.instance.codecId == 0){
                                    me.instance.codecId = data.data[0].id;
                                }
                            }
                        }, (err) => {
                        me.error = "Unable to load codecs, please try again later or contact an administrator.";
                    });  
                }
            }, (err) => {
                me.error = "Unable to load pipelines, please try again later or contact an administrator.";
            });
    }

    confirmCodeReplace(unsavedData: string){
        let me = this;
        let dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                title: "Confirm Code Replacement",
                message: "Previously unsaved code changes were detected.  Do you want to replace the saved code with the unsaved code?",
                ok: "Yes",
                cancel: "No"
            },
                disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            me.clearSavedCode();
            if(result){
                //There are 2 code change events generated on initialization of the editors
                //then 2 per changed editor when the code is reset from saved source.
                this.replaceCount =  2;
                if(unsavedData){
                    this.firstCodeChangeEvent = true;                    
                    me.instance.defaultDeviceDataStr = unsavedData;
                }
            }
        });
    }
    
    isAdmin(){
        return Utils.isAdminUser();
    }
    isDirty(){
        if(!this.changed && this.oldInst){
            this.changed = JSON.stringify(this.oldInst) != JSON.stringify(this.instance);
        }
        return this.changed;
    }
    ngAfterViewInit(){
        let unsavedData = this.options.getItem(this.devProfDataStorageKey, undefined);
        const tabGroup = this.tabGroup;     
        if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
      
        tabGroup.selectedIndex = this.selectedTab;
        if(unsavedData){
            this.confirmCodeReplace(unsavedData);
        }                
    }
    save(backOnSave:boolean = true) {
        this.error = "";
        if(this.inProgress || this.saveBlocked || !this.isDirty()){
            return;
        }
        if(this.isShared && !this.isAdmin()){
            this.error = "Cannot edit a shared Device Profile";
            return;
        }
        let defDataAnnErrors = this.deviceProfileDefDataEditor.getAnnotationErrors();
        if(defDataAnnErrors.length > 0){
            let firstError = defDataAnnErrors[0];
            let errLine = firstError.row + 1;
            let errCol = firstError.column + 1;
            let errText = firstError.text;
            this.error = "Code Error: " + errText + " at line " + errLine + " column " + errCol;
            return;
        }

        if(this.instance.name == undefined || this.instance.name == ""){
            this.error = "An name is required";
            return;
        }
        if(this.instance.uplinkPipelineId == undefined || this.instance.uplinkPipelineId == ""){
            this.error = "An uplink Pipeline is required";
            return;
        }
        if(this.instance.downlinkPipelineId == "None"){
            this.instance.downlinkPipelineId = null;
        }
        if(this.instance.defaultDeviceDataStr == undefined || this.instance.defaultDeviceDataStr == ''){
            this.instance.defaultDeviceDataStr = "{}";
        }
        try{
            this.instance.defaultDeviceData = JSON.parse(this.instance.defaultDeviceDataStr);
        }
        catch(e){
            this.error = "Default Device Data is not a valid JSON format";
            return;
        }
        this.instance.periodicPipelinePeriod = this.instance.periodicPipelinePeriodMins * 60000;
        var me = this;
        var obs;
        this.inProgress = true;
        if(this.editType == "Create"){
            obs = this.serve.create(this.instance);
        }
        else{
            obs = this.serve.edit(this.instance);
        }

        this.inProgress = true;
        obs.subscribe(resp => {
            me.inProgress = false;
            me.codeChanged = false;
            me.changed = false;
            this.oldInst = {...this.instance};
            if(backOnSave){
                this.goBack(me);
            }
        }, (err) => {
            me.inProgress = false;
            me.changed = false;            
            console.log(err);
            if(err && err.error && err.error.message){
                me.error = err.error.message;
                if(me.error == "Internal Server Error"){
                   me.error = "Server was unable to process your request";
                }
            }else{
                me.error = JSON.stringify(err);
                if(!me.error){
                    "An unknown server error occurred";
                }
            }
        });
    }

    onCodeChanged(){
        console.log("Data Code Changed: ");
        if(!this.firstCodeChangeEvent && this.replaceCount == 0){
            this.codeChanged = true;
            setTimeout(() => {  
                console.log("Saving key: " + this.devProfDataStorageKey + " to local storage");
                this.options.setItem(this.devProfDataStorageKey, this.instance.defaultDeviceDataStr);
            }, 50);
        }
        if(this.replaceCount > 0){
            console.log("Replace Change Count:" + this.replaceCount);
            this.replaceCount--;
        }        
        if(!this.firstCodeChangeEvent){
            this.codeChanged = true;
        }
        this.firstCodeChangeEvent = false;      
    }  

    handleBack($event){
        $event.preventDefault();
        this.cancel();
    }
    selectedTabChanged(tabChangeEvent: MatTabChangeEvent){
        let index = tabChangeEvent.index;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {"tab": index},
            replaceUrl: true,
            queryParamsHandling: 'merge'

        });        
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    } 

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    } 

    cancel(){
        let me = this;
        me.goBack(me);
    }  
    goBack(me){
        this.clearSavedCode();
        if(me.canGoBack){
            me.location.back();
        }else{
            me.router.navigateByUrl("/configurations/deviceprofiles",{
                replaceUrl: true
            });
        }
    }  
    clearSavedCode(){
        console.log("Removing key: " + this.devProfDataStorageKey + " from local storage");
        this.options.removeItem(this.devProfDataStorageKey);
    }        
}