import { Component, ViewChild, HostListener } from "@angular/core";
import { ActivatedRoute, Router} from "@angular/router";
import { AfterViewInit } from "@angular/core";
import * as flowchart from "flowchart.js";
import { MatSnackBar } from "@angular/material/snack-bar";
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import { MatDialog } from "@angular/material/dialog";
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { AESPipelinesService, Pipeline} from "../../../services/AESPipelinesService";
import { SenetAceEditor, ConfirmDialog, GlobalScope, HelpInfo, OptionsService  } from "aes-common";
import { Account, AESAccountService } from "../../../services/AESAccountService";
import { Utils } from "../../../services/Utils";

const MAX_TAB_INDEX = 2; //Change if tab count changes

@Component({
    selector: 'pipelinedetails',
    templateUrl: 'pipeline-details.html',
    styleUrls: ['./pipeline-details.scss']
})
export class PipelineDetails implements AfterViewInit {
    @ViewChild('pipelineFbEditor', {static: true}) pipelineFbEditor: SenetAceEditor;
    @ViewChild('pipelineConfigEditor', {static: true}) pipelineConfigEditor: SenetAceEditor;
    @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) { 
        if((event.ctrlKey || event.metaKey) && event.key == 's'){
            event.preventDefault();
            let backOnSave = this.editType === "Create";
            this.save(backOnSave);
        }
    }

    templateExample: HelpInfo = {label: "Define a Pipeline", url: GlobalScope.PIPELINE_EXAMPLE_URL};
    pipelineId: string;
    instance: any;
    editType: string = "Create";
    titleSuffix: string;
    title: string;
    pipeline: Pipeline;
    inProgress: boolean;
    saveBlocked: boolean;
    error: string;
    mode : string = "ace/mode/javascript";
    codeChanged: boolean = false;
    changed: boolean = false;
    canShow: boolean = false;
    selectedTab: number = 0;
    accounts: Account[];
    //2 Code editors
    firstCodeChangeEvent1: boolean = true;
    firstCodeChangeEvent2: boolean = true;

    functionBlockDefault: string = "system.success();";

    decoderDefault: string = "system.success();";
    gapSize: string = "10px";
    private canGoBack: boolean;
    private oldInst: any;
    private diagram: any;
    private diagramDrawn: boolean = false;
    private pipelineFBStorageKey: string;
    private pipelineConfigStorageKey: string;
    private replaceCount: number = 0;
    private DIAGRAM_INDEX = 2; //NOTE: Update if new tabs added or order changed.

    constructor(public dialog: MatDialog, private route: ActivatedRoute, public serve : AESPipelinesService,
        public snackBar: MatSnackBar, public titleService: Title, private router: Router, 
        private location: Location, accountSearch : AESAccountService, public options: OptionsService) {
    
        this.pipelineId = this.route.snapshot.params["id"];
        this.pipelineFBStorageKey = OptionsService.SENET_STORAGE_PREFIX + "pipelineFB_" + this.pipelineId;
        this.pipelineConfigStorageKey = OptionsService.SENET_STORAGE_PREFIX + "pipelineConfig_" + this.pipelineId;
        var acctId = undefined;
        if(this.route.snapshot.params["acctId"]){
            acctId = this.route.snapshot.params["acctId"];
        }
        this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);
        this.instance = {};

        if(this.pipelineId == '-1'){
            this.instance.functionBlocks = [];
            this.instance.config = {};            
            this.instance.code = this.functionBlockDefault;
            this.instance.functionBlocksStr = JSON.stringify(this.instance.functionBlocks, null, 4);
            this.instance.configStr = JSON.stringify(this.instance.config, null, 4);
            this.oldInst = {...this.instance};
            this.canShow = true;
        }else{
            this.serve.get(this.pipelineId, acctId).subscribe(res => {
                this.pipeline = res;
                var inst = this.pipeline;
                if (inst != undefined) {
                    this.instance = {...inst};
                    this.editType = "Edit";
                    this.title = this.pipeline.id;
                }
                else{
                    this.instance.functionBlocks = [];
                    this.instance.config = {};
                }
                this.titleService.setTitle(this.title);//Overridden if defined in app-routing module.ts
                if(this.instance.id != undefined && this.instance.id != ""){
                    this.titleSuffix = " - " + this.instance.id;
                }
                this.instance.functionBlocksStr = JSON.stringify(this.instance.functionBlocks, null, 4);
                this.instance.configStr = JSON.stringify(this.instance.config, null, 4);
                this.oldInst = {...this.instance};            
                this.canShow = true;
                if(!this.diagramDrawn && this.selectedTab == this.DIAGRAM_INDEX){
                    let me = this;
                    setTimeout(function () {
                        me.buildDiagram();
                    }, 50);
                }                                 

            }, err =>{
                this.openSnackBar("Unable to load Pipeline: " + this.pipelineId, undefined);
            }); 
        }

        if(this.isAdmin()){
            var me = this;
            this.inProgress = true;
            accountSearch.search(undefined, undefined, undefined, undefined, undefined, undefined)
            .subscribe(data => {
                me.accounts = data.data;
                if(me.accounts == undefined || me.accounts.length == 0){
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                }
                else{
                    me.inProgress = false;
                    if(me.instance.acctId == undefined){
                        me.instance.acctId = data.data[0].acctId;
                    }
                }
            }, (err) => {
            me.error = "Unable to load accounts, please try again later or contact an administrator.";
            });
        }
    }

    isAdmin(){
        return Utils.isAdminUser();
    }

    ngOnInit() {      
    }
    ngAfterViewInit(){   
        const tabGroup = this.tabGroup;
        let unsavedFunctionBlocks = this.options.getItem(this.pipelineFBStorageKey, undefined); 
        let unsavedConfig = this.options.getItem(this.pipelineConfigStorageKey, undefined);    
        let selectedUrlTab = Number.parseInt(this.route.snapshot.queryParamMap.get('tab'));
        this.selectedTab = selectedUrlTab >= 0 && selectedUrlTab < MAX_TAB_INDEX ? selectedUrlTab : 0;
        if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
      
        tabGroup.selectedIndex = this.selectedTab;
        if(unsavedFunctionBlocks || unsavedConfig){
            this.confirmCodeReplace(unsavedFunctionBlocks, unsavedConfig);
        }                           
    }
    confirmCodeReplace(unsavedFunctionBlocks: string, unsavedConfig:string){
        let me = this;
        let dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                title: "Confirm Code Replacement",
                message: "Previously unsaved code changes were detected.  Do you want to replace the saved code with the unsaved code?",
                ok: "Yes",
                cancel: "No"
            },
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            me.clearSavedCode();
            if(result){
                //There are 2 code change events generated on initialization of the editors
                //then either 4 or 2 more when the code is reset from saved source.
                this.replaceCount = unsavedFunctionBlocks && unsavedConfig ? 4 : 2;

                if(unsavedFunctionBlocks){
                    this.firstCodeChangeEvent1 = true;                    
                    unsavedFunctionBlocks = unsavedFunctionBlocks;
                    me.instance.functionBlocksStr = unsavedFunctionBlocks;
                }
                if(unsavedConfig){
                    unsavedConfig = unsavedConfig;
                    this.firstCodeChangeEvent2 = true;
                    me.instance.configStr = unsavedConfig;
                }
            }
        });
    }    
    buildDiagram(){
        let def:string = "";
        let next: number = 0;

        for(let i=0; i < this.instance.functionBlocks.length; i++){
            let id = this.instance.functionBlocks[i].ID;
            let isFunctionBlock = id.indexOf('FunctionBlock') > -1;
            if(id && id.replace){
                id = id.replace('FunctionBlock ','');
                id = id.replaceAll(' ', '');
            }
            if(isFunctionBlock){
                let url = "/configurations/functionblocks/details/" + id 
                def +=  "action" + i + "=>operation: " + id +":>" + url + "\n";
            }else{
                def +=  "action" + i + "=>operation: " + id + "\n";
            }
            
        }
        for(let i=0; i < this.instance.functionBlocks.length - 1; i++){
            next = i + 1;
            def +=  "action" + i + "(bottom)->action" + next + "(bottom)\n";
        }
        if(this.instance.functionBlocks.length > 1){
            this.diagram = flowchart.parse(def);
            this.diagram.drawSVG('diagram',{'x': -100, 'y': 0, 'line-length': 10});
            this.diagramDrawn = true;
        }
    }
    handleBack($event){
        $event.preventDefault();
        this.cancel();
    }
    selectedTabChanged(tabChangeEvent: MatTabChangeEvent){
        if(this.canShow){
            let index = tabChangeEvent.index;
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {"tab": index},
                replaceUrl: true,
                queryParamsHandling: 'merge'
    
            });      
            if(!this.diagramDrawn && index == this.DIAGRAM_INDEX){
                this.buildDiagram();
            } 
        }
    }
    isDirty(){
        if(!this.changed && this.oldInst){
            this.changed = JSON.stringify(this.oldInst) != JSON.stringify(this.instance);
        }
        return this.changed;
    }    
    save(backOnSave:boolean = true) {
        this.error = "";
        if(this.inProgress || this.saveBlocked || !this.isDirty()){
            return;
        }
        let fbAnnErrors = this.pipelineFbEditor.getAnnotationErrors();
        let configAnnErrors = this.pipelineConfigEditor ? this.pipelineConfigEditor.getAnnotationErrors() : [];
        if(fbAnnErrors.length > 0){
            let firstError = fbAnnErrors[0];
            let errLine = firstError.row + 1;
            let errCol = firstError.column + 1;
            let errText = firstError.text;
            this.error = "Code Error: " + errText + " at line " + errLine + " column " + errCol;
            return;
        }
        if(configAnnErrors.length > 0){
            let firstError = configAnnErrors[0];
            let errLine = firstError.row + 1;
            let errCol = firstError.column + 1;
            let errText = firstError.text;
            this.error = "Configuration Error: " + errText + " at line " + errLine + " column " + errCol;
            return;
        } 

        if(this.instance.id == undefined || this.instance.id == ""){
            this.error = "An ID is required";
            return;
        }
        if(this.instance.functionBlocksStr == undefined || this.instance.functionBlocksStr == ""){
            this.error = "A Pipeline definition is required";
            return;
        }
        try{
            this.instance.functionBlocks = JSON.parse(this.instance.functionBlocksStr);
            if(!Array.isArray(this.instance.functionBlocks)){
                this.error = "Function Blocks must be in an array";
                return;
            }
        }
        catch(e){
            this.error = "Function Blocks is not a valid JSON format";
            return;
        }
        if(this.instance.configStr == undefined || this.instance.configStr == ''){
            this.error = "Configuration are required";
            return;
        }
        try{
            this.instance.config = JSON.parse(this.instance.configStr);
        }
        catch(e){
            this.error = "Configuration is not a valid JSON format";
            return;
        }
        var me = this;
        var obs;
        this.inProgress = true;
        if(this.editType == "Create"){
            obs = this.serve.create(this.instance);
        }
        else{
            obs = this.serve.edit(this.instance);
        }

        obs.subscribe(resp => {
            me.oldInst = {...me.instance};
            me.inProgress = false;
            me.codeChanged = false;
            me.changed = false;
            if(backOnSave){
                me.goBack(me);
            }else{
                this.clearSavedCode();
            }
        }, (err) => {
            me.inProgress = false;
            me.changed = false;            
            console.log(err);
            if(err && err.error && err.error.message){
                me.error = err.error.message;
                if(me.error == "Internal Server Error"){
                   me.error = "Server was unable to process your request";
                }
            }else{
                me.error = JSON.stringify(err);
                if(!me.error){
                    "An unknown server error occurred";
                }
            }
        });
    }
    onCodeChanged(codeId){
        if(!this.firstCodeChangeEvent1 && !this.firstCodeChangeEvent2 && this.replaceCount == 0){
            this.codeChanged = true;
            setTimeout(() => {  
                if(codeId == 'pipelineFbEditor'){
                    this.options.setItem(this.pipelineFBStorageKey, this.instance.functionBlocksStr)
                }
                if(codeId == 'pipelineConfigEditor'){
                    this.options.setItem(this.pipelineConfigStorageKey, this.instance.configStr)
                }
            }, 50);
        }
        if(this.replaceCount > 0){
            this.replaceCount--;
        }
        if(codeId == 'pipelineFbEditor'){
            this.firstCodeChangeEvent1 = false;
        }
        if(codeId == 'pipelineConfigEditor'){
            this.firstCodeChangeEvent2 = false;
        }        
    }    
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    } 
    
    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    } 
    cancel(){
        let me = this;
        me.goBack(me); 
    }  
    goBack(me){
        this.clearSavedCode();
        if(me.canGoBack){
            me.location.back();
        }else{
            me.router.navigateByUrl("/configurations/pipelines",{
                replaceUrl: true
            });
        }
    }
    clearSavedCode(){
        this.options.removeItem(this.pipelineFBStorageKey);
        this.options.removeItem(this.pipelineConfigStorageKey);
    }       
    ngOnDestroy(){
        if(this.diagram){
            this.diagram = undefined;
        }
    }       
}