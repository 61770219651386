import { Component, ElementRef, AfterViewInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {MatGridListModule} from '@angular/material/grid-list';
import { OptionsService, ViewRegistration } from "aes-common";
import { AESPipelinesService } from "src/services/AESPipelinesService";
import { AESReportService } from "src/services/AESReportService";
import { Utils } from "src/services/Utils";
import 'chartjs-adapter-date-fns';
import { AESAccountService } from "src/services/AESAccountService";
import { tick } from "@angular/core/testing";

@Component({
    selector: 'health-report',
    templateUrl: 'health-report.html',
    styleUrls: ['./health-report.scss']
})
export class HealthReport implements AfterViewInit {
    public intervals = [
        {id: "1", label: "Last 1 Hour"},
        {id: "2", label: "Last 2 Hours"},
        {id: "4", label: "Last 4 Hours"},
        {id: "6", label: "Last 6 Hours"},
        {id: "8", label: "Last 8 Hours"},
        {id: "10", label: "Last 10 Hours"},
        {id: "12", label: "Last 12 Hours"}
    ]   
    static view = new ViewRegistration("Health Report", "/reports/healthreport", "", [], Utils.isAdminUser);
    static SEN_HEALTH_REPORT_ACCT_KEY =  "SEN_HEALTH_REPORT_ACCT";
    static SEN_HEALTH_REPORT_INTERVAL_KEY =  "SEN_HEALTH_REPORT_INTERVAL";
    static SEN_HEALTH_REPORT_INTERVAL_DEFAULT =  "1";
    gapSize: string = "10px";
    type: string;
    deviceTypeChartType: string;
    logCountChartType: string;
    uplinkData: any;
    deviceTypeData: any;
    deviceTypeOptions: any;
    logCountData: any;
    logCountOptions: any;any;    
    inProgress: boolean = false;
    error: string;
    selectedAccount: string;
    selectedInterval: string;
    accounts: any[];

    constructor(private el: ElementRef, public snackBar: MatSnackBar, public pipelineService: AESPipelinesService, 
        public reportService: AESReportService, accountService: AESAccountService, private userOptions: OptionsService) {
            let me = this;
            this.selectedInterval = this.userOptions.getItem(HealthReport.SEN_HEALTH_REPORT_INTERVAL_KEY, HealthReport.SEN_HEALTH_REPORT_INTERVAL_DEFAULT);
            this.inProgress = true;
            if(Utils.isAdminUser()){
                accountService.search(undefined, undefined, undefined, undefined, undefined, undefined)
                .subscribe(data => {
                    me.accounts = this.filterAccounts(data.data);
                    if(me.accounts == undefined || me.accounts.length == 0){
                        me.error = "Unable to load accounts, please try again later or contact an administrator.";
                        me.inProgress = false;
                    }
                    else{
                        me.selectedAccount = userOptions.getItem(HealthReport.SEN_HEALTH_REPORT_ACCT_KEY, me.accounts[0].acctId.toString());
                        let accountNumeric = Number.parseInt(me.selectedAccount);
                        //Signature accountId: number, start: string, end: string, limit: number, interval: string
                        reportService.getHealthReportData(accountNumeric, undefined, undefined, undefined, me.selectedInterval).subscribe(data => {
                            console.log(data);
                            me.inProgress = false;
                            me.buildReport(data);
                        }, (err) => {
                            console.log(err);
                            me.inProgress = false;
                        });
                    }
                }, (err) => {
                    me.error = "Unable to load accounts, please try again later or contact an administrator.";
                    me.inProgress = false;
                });
            }  
            else{
                        
                //Signature accountId: number, start: string, end: string, limit: number, interval: string
                reportService.getHealthReportData(undefined, undefined, undefined, undefined, undefined).subscribe(data => {
                    console.log(data);
                    me.buildReport(data);
                    me.inProgress = false;
                }, (err) => {
                    console.log(err);
                    me.inProgress = false;
                });
            }          

    }
    private filterAccounts(data){
        let filtered = [];
        for(let acct of data){
            if(acct.acctId != -1){
                filtered.push(acct);
            }
        }
        return filtered;
    }

    private createFakeTypeData(rowData){
        let seriesCount = 30;
        let newRowData = [];
        for(let i = 0; i < rowData.length; i++){
            let row = rowData[i];
            newRowData.push(row);
            for(let j = 0; j < seriesCount; j++){
                let newRow = Object.assign({}, row);
                newRow.devType = newRow.devType + j;
                newRowData.push(newRow);
            }
        }
        return newRowData;
    }
    private createFakeCountData(rowData){
        let seriesCount = 30;
        for(let i = 0; i < rowData.length; i++){
            let row = rowData[i];
            let count = row.devCount;
            for(let j = 0; j < seriesCount; j++){
                row.devCount += count;
            }
        }
        return rowData;
    }    
    private createFakeLogData(rowData){
        let seriesLabels = ["ERROR", "WARN", "INFO", "DEBUG", "TRACE"];
        let seriesCount = 5;
        let newRowData = [];
        for(let i = 0; i < rowData.length; i++){
            let row = rowData[i];
            newRowData.push(row);
            for(let j = 0; j < seriesCount; j++){
                let newRow = Object.assign({}, row);
                newRow.type = seriesLabels[j % seriesLabels.length];
                newRowData.push(newRow);
            }
        }
        return newRowData;
    }    
    private buildReport(data){
        let me = this;
        let typeDataRows = data.deviceTypes.data;
        let logCountDataRows = data.logCounts.data;
        let legendSortMap = {"ERROR":0, "WARN":1, "INFO":2, "DEBUG":3, "TRACE":4};
        /*
        if(true){
            typeDataRows = this.createFakeTypeData(typeDataRows);
            logCountDataRows = this.createFakeLogData(logCountDataRows);
        }
        */
        
        this.deviceTypeData = this.rowsToChartData(typeDataRows, 'devType', 'devCount', AESReportService.COLORS_RGBA);
        this.logCountData = this.rowsToChartData(logCountDataRows, 'type', 'count', AESReportService.LOG_LEVEL_COLORS);
        this.deviceTypeChartType = 'bar';//dumb, but if not different vars, chartjs draws incorrectly
        this.logCountChartType = 'bar';
        this.deviceTypeOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'start'
                },
                tooltip: {
                    callbacks: {
                      footer: function(items) {
                        let total = 0;
                        if(items.length > 0 && items[0].dataset.data != undefined && items[0].dataset.data && items[0].dataset.data.length > 0){
                            let time = items[0].parsed.x
                            items[0].chart.config._config.data.datasets.forEach(dataset => { 
                                if(dataset.data && dataset.data.length > 0){
                                    dataset.data.forEach(data => {
                                        if(data.x == time){
                                            total += data.y;
                                        }
                                    });
                                }
                            });
                        }
                        return 'Total: ' + total;
                      }
                    }
                }                                               
            },
            scales: {
                x: {
                    stacked: true,
                    type: 'time',
                    ticks: {
                        autoSkip: true,
                        source: 'labels',
                        maxRotation: 0, 
                        major: {
                            enabled: true
                        }                             
                    },
                    time: {
                        unit: 'minute',
                        displayFormats: {
                            day: 'LLL dd, yyyy',
                            hour: 'h:mm a',
                            minute: 'h:mm a'
                        }                        
                    },  
                },
                y:{
                    stacked: true,
                    grid: { 
                        border: {
                            display: true
                        }
                    }
                }
            }
        };
        
        this.logCountOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        sort: function(a, b, chartData) {
                            return legendSortMap[a.text] - legendSortMap[b.text];
                        }
                    }

                },
                tooltip: {
                    callbacks: {
                      footer: function(items) {
                        let total = 0;
                        if(items.length > 0 && items[0].dataset.data != undefined && items[0].dataset.data && items[0].dataset.data.length > 0){
                            let time = items[0].parsed.x
                            items[0].chart.config._config.data.datasets.forEach(dataset => { 
                                if(dataset.data && dataset.data.length > 0){
                                    dataset.data.forEach(data => {
                                        if(data.x == time){
                                            total += data.y;
                                        }
                                    });
                                }
                            });
                        }
                        return 'Total: ' + total;
                      }
                    }
                }                                               
            },
            scales: {
                x: {
                    stacked: true,
                    type: 'time',
                    ticks: {
                        autoSkip: true,
                        source: 'labels',
                        maxRotation: 0,
                       major: {
                        enabled: true
                       }                
                    },
                    time: {
                        unit: 'minute',
                        displayFormats: {
                            day: 'LLL dd, yyyy',
                            hour: 'h:mm a',
                            minute: 'h:mm a'
                        }                        
                    },
                    
                },
                y:{
                    stacked: true,
                    grid: { 
                        border: {
                            display: true
                        }
                    },
                    ticks: {stepSize:1}
                }
            }
          };     
    }

    private rowsToChartData(rows, seriesAttribute, valueAttribute, colors) {
        let isColorsArray = Array.isArray(colors);
        let labels = this.reportService.generateLabelArray(this.selectedInterval);
        //let dataMap = {datasets: [], labels: []};
        let dataMap = {datasets: [], labels: labels};
        let dataSetMap = {};
        let index = 0;

        rows.forEach(row => {
            let seriesKey = row[seriesAttribute] ? row[seriesAttribute] : seriesAttribute;
            let timestamp = Date.parse(row.time);
            /*
            if(dataMap['labels'].indexOf(timestamp) == -1){
                dataMap['labels'].push(timestamp);
            }
            */
            if(!dataSetMap.hasOwnProperty(seriesKey)){
                let colorsKey = isColorsArray ? index : seriesKey;
                dataSetMap[seriesKey] = {
                    label: seriesKey,
                    fill: false,
                    data: [{"x": timestamp, "y": row[valueAttribute]}],
                    backgroundColor: colors[colorsKey], //each series should  have a unique color
                    borderColor: colors[colorsKey].replace(".5", "1"),
                    maxBarThickness: 25
                }
                index++;
            }else{
                dataSetMap[seriesKey].data.push({"x": timestamp, "y": row[valueAttribute]});
            }
            
        });
        for(let key in dataSetMap){
                dataMap.datasets.push(dataSetMap[key]);
        }
        return dataMap;
    }

    ngAfterViewInit(){  
    }

    accountChanged(accountId){
        this.selectedAccount = accountId;
        this.userOptions.setItem(HealthReport.SEN_HEALTH_REPORT_ACCT_KEY,this.selectedAccount);
        this.updateReport();
    }
    intervalChanged(interval){
        this.selectedInterval = interval;
        this.userOptions.setItem(HealthReport.SEN_HEALTH_REPORT_INTERVAL_KEY,this.selectedInterval);
        this.updateReport();
    }   
    updateReport(){
        let accountNumeric = Number.parseInt(this.selectedAccount);
        //Signature accountId: number, start: string, end: string, limit: number, interval: string
        this.reportService.getHealthReportData(accountNumeric, undefined, undefined, undefined, this.selectedInterval).subscribe(data => {
            console.log(data);
            this.buildReport(data);
        }, (err) => {
            console.log(err);
        });
    }       
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    } 
    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}