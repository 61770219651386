import { Component, HostListener, Input, ViewChild } from "@angular/core";
import {ActivatedRoute, Router} from '@angular/router';
import { ConfirmDialog, ViewRegistration } from "aes-common";
import { ColumnConfig } from "aes-datatables";
import { ButtonConfig, DataTableOptions, DataTable } from "aes-datatables";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AESDeviceProfileService, DeviceProfile } from "../../services/AESDeviceProfileService";
import { Utils } from '../../services/Utils';

@Component({
    template: "<a [routerLink]=\"['/configurations/pipelines/details',element.uplinkPipelineId]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.uplinkPipelineId}}</a>"
})
export class PipelineCellLink{
    @Input()
    element: DeviceProfile;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    } 
}

@Component({
    template: "<a [routerLink]=\"['/configurations/pipelines/details',element.downlinkPipelineId]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.downlinkPipelineId}}</a>"
})
export class DownlinkPipelineCellLink{
    @Input()
    element: DeviceProfile;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    } 
}


@Component({
    template: "<a [routerLink]=\"['/configurations/pipelines/details',element.periodicPipelineId]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.periodicPipelineEnabled ? element.periodicPipelineId : \"\"}}</a>"
})
export class PipelinePeriodicCellLink{
    @Input()
    element: DeviceProfile;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    }  
}


@Component({
    template: "<a [routerLink]=\"['/configurations/deviceprofiles/details',element.id]\" [queryParams]=\"{'hideNav': hideNav}\">{{element.name}}</a>"
})
export class ShowDeviceProfileEditorCell{
    @Input()
    element: DeviceProfile;

    @Input()
    col: ColumnConfig;
    hideNav: boolean;
    constructor(public route: ActivatedRoute){}
    ngOnInit() {
        this.route.queryParams
          .subscribe(params => {
            //console.log(params); 
            this.hideNav = params.hideNav ==  true ||  params.hideNav ==  "true" ? true : false;
            //console.log(this.visible); 
          }
        );
    } 
}

@Component({
    selector: 'device-profiles',
    templateUrl: 'device-profiles.html',
    styleUrls: ['./device-profiles.scss']
})

export class AESDeviceProfiles {
    static view = new ViewRegistration("Device Profiles", "/configurations/deviceprofiles", "developer_mode", []);

    dtOptions:DataTableOptions = {pageSize : 100, showSelectionColumn: false, multiSelect: false, columnFiltering: true, filtering: false};

    @ViewChild(DataTable, { static: true })
    dt : DataTable
    
    columnCfgs: ColumnConfig[] = [{
        header: "ID",
        field: "id",
        defaultSort: true,
    },{
        header: "Name",
        field: "name",
        filterable: true,
        contentComponent: ShowDeviceProfileEditorCell
    }, {
        header: "Description",
        field: "description",
        filterable: true
    }, {
        header: "Creation",
        title: "Creation",
        field: "creationDate",
        dataType: 'date',
        filterable: true,
        renderContent: function(col: ColumnConfig, element: DeviceProfile){
            var date = new Date(element.created);
            return date.toLocaleString();
        }
   	},{
        header: "Created By",
       	field: "createdBy",
       	filterable: false,
       	visible : false
    },{
        header: "Updated",
        title: "Updated",
        field: "lastUpdated",
        dataType: 'date',
        filterable: true,
        renderContent: function(col: ColumnConfig, element: DeviceProfile){
            var date = new Date(element.lastUpdated);
            return date.toLocaleString();
        }
    },{
    	header: "Modified By",
    	field: "modifiedBy",
    	filterable: false,
    	visible : false
    },{
        header: "Account",
        field: "acctId",
        filterable: true,
        visible : false
    },{
        header: "Uplink Pipeline",
        field: "uplinkPipelineId",
        filterable: true,
        contentComponent: PipelineCellLink
    },{
        header: "Downlink Pipeline",
        field: "downlinkPipelineId",
        filterable: true,
        contentComponent: DownlinkPipelineCellLink
    }, {
        header: "Periodic Pipeline Enabled",
        field: "periodicPipelineEnabled",
        visible: false,
        renderContent: function(col: ColumnConfig, element: DeviceProfile){
            return element.periodicPipelineEnabled ? "Enabled" : "Disabled";
        }
    }, {
        header: "Periodic Pipeline",
        field: "periodicPipelineId",
        filterable: true,
        contentComponent: PipelinePeriodicCellLink
    }, {
        header: "Periodic Pipeline Interval",
        field: "periodicPipelinePeriod",
        renderContent(col, element) {
            if(!element.periodicPipelineEnabled){
                return "N/A";
            }
            if(element.periodicPipelinePeriod !== undefined){
                return (element.periodicPipelinePeriod / 60000) + " Minutes";
            }
            return "";
        },
    }, {
        header: "Codec",
        field: "codecId"
    }];

    buttons: ButtonConfig[];


    gapSize: string = "10px";

    constructor(public dialog: MatDialog, public httpClient: AESDeviceProfileService,  
        public snackBar: MatSnackBar, private router: Router) {
        var me = this;
        let userDelete = ButtonConfig.createDeleteButton("Delete Device Profile", function(selected){
            return "Are you sure you want to delete Device Profile " + selected.id + "?";
        }, function(selected, dt){
            me.httpClient.delete(selected)
            .subscribe(resp => {
                me.openSnackBar(selected.id + " Deleted!", undefined);
                dt.refresh(dt, selected);
            }, (err) => {
               console.log(err);
               var message = err.error.message;
               if(message == undefined){
                message = "Unknown";
               }
               if(message == "Internal Server Error"){
                message = "Server was unable to process your request";
               }
               var error = "Error deleting entry: " + message;
               
               me.openSnackBar(error, undefined);
               dt.refresh(dt, selected);
            });
        },dialog);
        this.buttons = [ButtonConfig.createAddButton("Create Device Profile", function(dt, selected){
            me.router.navigateByUrl("configurations/deviceprofiles/details/-1");
        }), ButtonConfig.createEditButton("Edit Device Profile", function(dt, selected){
            me.router.navigateByUrl("configurations/deviceprofiles/details/" + selected[0].id);
        })];
        this.buttons.push(userDelete);
        
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'right'
        });
    }

    calculateSize(percent) {
        if (percent == undefined) {
            percent = 100;
        }
        if (!isNaN(percent)) {
            percent = percent + "%";
        }
        else {
            percent = percent.toString();
            if (percent.index("%") < 0) {
                percent += "%";
            }
        }
        if (this.gapSize == undefined) {
            return percent;
        }
        return "calc(" + percent + " - " + this.gapSize + ")"
    }
}
