<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography">
    {{message}}
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!isOkDialog">
    <button mat-button (click)="cancel()">No</button>
    <button mat-button (click)="confirm()" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
<mat-dialog-actions align="end" *ngIf="isOkDialog">
    <button mat-button (click)="cancel()">Ok</button>
</mat-dialog-actions>