import { Component, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import * as ace from "ace-builds";
import { ElementRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { js_beautify } from 'js-beautify';
import { AfterViewInit, OnDestroy, OnChanges } from "@angular/core";
import { OptionsService} from "../../services/Options";
import { GlobalScope } from "../../services/GlobalScope";

@Component({
    selector: 'senet-ace-editor',
    templateUrl: 'senet-ace-editor.html',
    styleUrls: ['./senet-ace-editor.scss']
})
export class SenetAceEditor implements AfterViewInit, OnDestroy, OnChanges {

    aceEditor: ace.Ace.Editor;
    fullscreen: boolean;
    @ViewChild("editor") private editor: ElementRef<HTMLElement>;
    theme: string;
    @Input() mode : string;
    @Input() code : string;
    @Input() isForDialog: boolean = true;
    @Output() codeChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() fullscreened: EventEmitter<boolean> = new EventEmitter<boolean>();

    private editorTabSpaces = 4;
    private editorTabsAsSpaces = true;

    constructor( public options : OptionsService) {
        this.theme = GlobalScope.ACE_THEME;
        this.onCodeChange = this.onCodeChange.bind(this);
        this.editorTabSpaces = options.getItem(OptionsService.SENET_ACE_TAB_SPACES_KEY, 4);
        this.editorTabsAsSpaces = options.getItem(OptionsService.SENET_ACE_TABS_AS_SPACES_KEY, true);
    }

    getAceEditor(){
        return this.aceEditor; 
    }

    getAnnotationErrors(){
        let annErrors = [];

        let annotations = this.aceEditor.getSession().getAnnotations();
        if(annotations.length == 1 && annotations[0].text == 'Too many errors. (100% scanned).'){
            this.aceEditor.getSession().setAnnotations([]);
            annotations = this.aceEditor.getSession().getAnnotations();
        }          
        if(annotations && annotations.length > 0){
            annotations.forEach(function(annotation){
                if(annotation.type == "error"){
                    if(annotation.text.indexOf('Bad option') < 0){
                        annErrors.push(annotation);
                    }
                }else if(annotation.type == "warning" && annotation.text.indexOf('Unrecoverable syntax') > -1){
                    annErrors.push(annotation);
                }
            });
        }
        return annErrors;
    }  

    onCodeChange(){
        this.code = this.aceEditor.getValue();
        this.codeChange.emit(this.code);
    }
    
    toggleFullScreen(){
        this.fullscreen = !this.fullscreen;
        this.fullscreened.emit(this.fullscreen);
        var me = this;
        setTimeout(() => {
            me.aceEditor.resize(true);
        }, 500);
    }

    formatCode(){
        let options = { indent_size: this.editorTabSpaces, space_in_empty_paren: true };
        this.code = js_beautify(this.code, options);
        this.aceEditor.setValue(this.code);
    }
    ngAfterViewInit(){
        let me = this;
        ace.config.set("fontSize", "12px");
        var langTools = ace.require("ace/ext/language_tools");
        var staticWordCompleter = {
            getCompletions: function(editor, session, pos, prefix, callback) {
                var wordList = ["input", "output"];
                var inputList = ["device", "system", "lnsMetadata", "downlink"];
                var outputList = ["notify", "scratch", "device"];
                let thisList = ["getLookupEntry", "logDebug", "logError", "logWarn", "logTrace", "logInfo", "hexToSignedInt", "toHexString", "hexToUnsignedInt", "isHex", "reverseByteOrder", "getBytes"];
                var inputSystem = ["rootScope", "success", "failure", "functionBlockCfg", "statistics"];
                var notify = ["fields", "config"];
                var deviceList = ["lastUplinkTime", "devEui", "name", "devType", "dynamicState", "configuredState", "owner", "deviceClass", "dateCreated", "deviceLocation", "tags", "deviceProfile", "application", "statistics", "nextTimerTime"];
                var line = session.getLine(pos.row);
                if(line.match(/input\.device/) || line.match(/output\.device/)){
                    callback(null, deviceList.map(function(word) {
                        return {
                            caption: word,
                            value: word,
                            meta: "static",
                            score: 1000
                        };
                    }));
                }
                else if(line.match(/output\.notify/)){
                    callback(null, notify.map(function(word) {
                        return {
                            caption: word,
                            value: word,
                            meta: "static",
                            score: 1000
                        };
                    }));
                }
                else if(line.match(/input\.system/) || line.match(/system\.[a-zA-Z]+$/)){
                    callback(null, inputSystem.map(function(word) {
                        return {
                            caption: word,
                            value: word,
                            meta: "static",
                            score: 1000
                        };
                    }));
                }
                else if(line.match(/input\.[a-zA-Z]+$/)){
                    callback(null, inputList.map(function(word) {
                        return {
                            caption: word,
                            value: word,
                            meta: "static",
                            score: 1000
                        };
                    }));
                }
                else if(line.match(/output\.[a-zA-Z]+$/)){
                    callback(null, outputList.map(function(word) {
                        return {
                            caption: word,
                            value: word,
                            meta: "static",
                            score: 1000
                        };
                    }));
                }
                else if(line.match(/this\.[a-zA-Z]+$/)){
                    callback(null, thisList.map(function(word) {
                        return {
                            caption: word,
                            value: word,
                            meta: "static",
                            score: 1000
                        };
                    }));
                }                
                else{
                    callback(null, wordList.map(function(word) {
                        return {
                            caption: word,
                            value: word,
                            meta: "static",
                            score: 1000
                        };
                    }));
                }        
            }
        }
        
        langTools.setCompleters([staticWordCompleter])
    
        ace.config.set(
        "basePath",
        "https://unpkg.com/ace-builds@1.4.12/src-noconflict"
        );
        this.aceEditor = ace.edit(this.editor.nativeElement);
        this.aceEditor.setOptions({
            enableBasicAutocompletion: true,
            enableSnippets: true,
            enableLiveAutocompletion: true
        }); 
        this.aceEditor.commands.addCommand({
            name: 'formatCode',
            bindKey: {
              win: 'Ctrl-Shift-F',
              mac: 'Command-Shift-F'
            },
            exec: function(editor) {
                console.log("formatCode");
                me.formatCode();
            },
            readOnly: false // false if this command should not apply in readOnly mode
          });          
        this.aceEditor.session.setOptions({ tabSize: this.editorTabSpaces, useSoftTabs: this.editorTabsAsSpaces });    
        this.aceEditor.session.addEventListener('changeMode', function(e, session){
            if ("ace/mode/javascript" === session.getMode().$id) {
              if (!!session.$worker) {
                session.$worker.send("setOptions", [{
                  "-E043": false,//Disable too many errors warning
                  "esversion": 9,//Disable ES version warnings
                  "esnext": false,                  
                }]);
              }
            }
        }); 
        this.aceEditor.session.setMode(this.mode);
        this.aceEditor.setTheme(this.theme);
        this.aceEditor.on("change", this.onCodeChange);
        this.aceEditor.session.setValue(this.code);                 
    }

    ngOnDestroy(): void {
        if(this.aceEditor){
            this.aceEditor.off("change", this.onCodeChange);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.aceEditor && this.aceEditor.getValue() != this.code){
            this.aceEditor.session.setValue(this.code);
        }
    }

}
