import { Component, Input, Host, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { DetailsView } from "../detailsview";
import { Subscription } from "rxjs";
import { GlobalScope } from "../../../services/GlobalScope";

@Component({
    selector: 'details-nav-view',
    templateUrl: 'details-nav-view.html',
    styleUrls: ['./details-nav-view.scss']
})
export class DetailsNavView implements AfterViewInit,OnInit,OnDestroy {

    @Input()
    public id: any;

    public showView: boolean = false;

    public selection: any;

    parent: DetailsView

    subscription: Subscription;
    
    constructor(@Host() parent: DetailsView){
        this.parent = parent;
        this.selection = parent.selection;
    }

    ngAfterViewInit(){
        
    }

    ngOnInit(){
        this.showView = this.selection == this.id && !GlobalScope.isViewHidden(this.id);
        this.parent.selectionEvt$.subscribe(selection => {
            console.log("View Changed to: " + selection + " my id: " + this.id);
            this.showView = selection == this.id && !GlobalScope.isViewHidden(this.id);
            this.selection = selection;
        });
    }

    ngOnDestroy(){
        if(this.subscription){
            this.subscription.unsubscribe();
        }
    }
}