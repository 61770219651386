import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface RemoteFunctionBlock {
    id: string;
    name: string;
    description: string;
    code: any;
    lastUpdated: string;
    type: string;
    acctId: number;
    init: any;
    pipelines?: string[];
}

export interface CustomRemoteFunctionBlockFilter {
    type? : string
    acctId? : any
    fields? : string[]
}

@Injectable()
export class AESRemoteFunctionBlocksService implements DataTableSearchService<RemoteFunctionBlock>, CRUDService<RemoteFunctionBlock>{

    public static FUNCTION_BLOCK_TYPES : string[] = ["Encoder", "Decoder", "Normalizer", "Denormalizer", "Transformer", "Translator"];

    constructor(private http: HttpClient) { }

    create(object: RemoteFunctionBlock): Observable<Message> {
        const href = 'remotefunctionblocks';
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object);
    }
    edit(object: RemoteFunctionBlock): Observable<Message> {
        const href = 'remotefunctionblocks';
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object);
    }
    delete(object: RemoteFunctionBlock): Observable<Message> {
        const href ='remotefunctionblocks';
        var params = new HttpParams().set("id", object.id);
        var requestUrl = `${href}`
        
        if(object.acctId != undefined){
            params = params.set("acctId", object.acctId.toString());
        }

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }
    get(id, acctId?): Observable<RemoteFunctionBlock> {
        const href = 'remotefunctionblocks';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id.toString());
        }

        if(acctId != undefined){
            params = params.set("acctId", acctId.toString());
        }

        return this.http.get<RemoteFunctionBlock>(requestUrl, {
            params : params
        });
    }
    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: CustomRemoteFunctionBlockFilter, columns: any): Observable<SenetData<RemoteFunctionBlock>> {
        const href = 'remotefunctionblocks';
        
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();

        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        } 
        if(customFilter && customFilter.type != undefined){
            params = params.set("type", customFilter.type);
        }

        if(customFilter && customFilter.acctId != undefined){
            params = params.set("acctId", customFilter.acctId);
        }

        if(customFilter && customFilter.fields && customFilter.fields.length > 0){
            params = params.set("fields", customFilter.fields.join(","));
        }

        return this.http.get<SenetData<RemoteFunctionBlock>>(requestUrl, {
            params : params
        });
    }
}