<detailsview [config]="config" *ngIf="device != undefined">
    <detailsimg>
        <mat-icon class="device-img">devices</mat-icon>
        <div class="corner-buttons">
            <button [ngClass]="{'hidden': hideSendCommand}" mat-icon-button aria-hidden="false" aria-label="Send Command" matTooltip="Send Command"
                (click)="lunchSendCommand()">
                <mat-icon>add_to_home_screen</mat-icon>
            </button>
            <button mat-icon-button aria-hidden="false" aria-label="Edit Configuration" matTooltip="Edit Configuration"
            (click)="launchEdit()">
            <mat-icon>construction</mat-icon>
        </button>
        </div>
    </detailsimg>
    <detailsinfopnl id="info">
        <table class="info-table">
            <tr>
                <td>Device:</td>
                <td><a target="_blank" href="https://portal.senetco.io/#/ap/devices/details?devEui={{device.devEui}}&view=Transactions">{{device.devEui}}</a></td>
            </tr>           
            <tr>
                <td>Name:</td>
                <td>{{device.name}}</td>
            </tr>
            <tr>
                <td>Device Type:</td>
                <td>{{device.devType}}</td>
            </tr>
            <tr>
                <td>Owner:</td>
                <td>{{device.owner}}</td>
            </tr>
            <tr>
                <td>Profile:</td>
                <td><a target="_blank" href="/configurations/deviceprofiles/details/{{device.deviceProfile}}?hideNav=false">{{device.deviceProfileName ? device.deviceProfileName : device.deviceProfile}}</a></td>
            </tr>
            <tr>
                <td>Application:</td>
                <td><a target="_blank" href='/configurations/applications?hideNav=false'>{{device.application}}</a></td>
            </tr>            
            <tr>
                <td>Created:</td>
                <td>{{convertDate(device.dateCreated)}}</td>
            </tr>
            <tr>
                <td>Last Uplink Time:</td>
                <td>{{convertDate(device.lastUplinkTime)}}</td>
            </tr>
            <tr *ngIf="device.nextTimerTime">
                <td>Next Timer Time:</td>
                <td>{{convertDate(device.nextTimerTime)}}</td>
            </tr>             
            <!--
            <tr>
                <td>Location:</td>
                <td>{{device.deviceLocation}}</td>
            </tr>
            <tr>
                <td>Device Class:</td>
                <td>{{device.deviceClass}}</td>
            </tr>
            -->
            <tr>
                <td>Tags:</td>
                <td>{{device.tags}}</td>
            </tr>
        </table>
    </detailsinfopnl>
    <div subviews>
    </div>
    <div views>
        <details-nav-view [id]="'Device Details Events'">
            <device-events-panel [device]="device" *ngIf="device">
            </device-events-panel>
        </details-nav-view>
        <details-nav-view [id]="'Device Details Map'">
            <device-maps-panel [device]="device" *ngIf="device">
            </device-maps-panel>
        </details-nav-view>
        <details-nav-view [id]="'DynamicState'">
            <device-dynamicstate-panel [device]="device">
            </device-dynamicstate-panel>
        </details-nav-view>
        <details-nav-view [id]="'ConfiguredState'">
            <device-configuredstate-panel [device]="device">
            </device-configuredstate-panel>
        </details-nav-view>                
    </div>
</detailsview>