import { DataTableSearchService } from "aes-datatables";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SenetData } from "aes-datatables";
import { Injectable } from "@angular/core";
import { CRUDService, Message } from "aes-common";

export interface DeviceProfile {
    id: number;
    acctId: number;
    name: string;
    desciption: string;
    periodicPipelineId: string;
    lastUpdated: Date;
    created: Date;
    periodicPipelineEnabled: boolean;
    periodicPipelinePeriod: number;
    uplinkPipelineId: string;
    downlinkPipelineId?: string;
}
@Injectable()
export class AESDeviceProfileService implements DataTableSearchService<DeviceProfile>, CRUDService<DeviceProfile>{

    constructor(private http: HttpClient) { }

    create(object: any): Observable<Message> {
        const href = 'devices/profiles';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.post<Message>(requestUrl, object, {
            params : params
        });
    }
    edit(object: any): Observable<Message> {
        const href = 'devices/profiles';
        var params = new HttpParams();
        var requestUrl = `${href}`

        return this.http.put<Message>(requestUrl, object, {
            params : params
        });
    }
    get(id): Observable<DeviceProfile> {
        const href = 'devices/profiles';
        var requestUrl = `${href}`;
        var params = new HttpParams();
        if(id != undefined){
            params = params.set("id", id.toString());
        }

        return this.http.get<DeviceProfile>(requestUrl, {
            params : params
        });
    }

    delete(object: DeviceProfile): Observable<Message> {
        const href ='devices/profiles';
        var params = new HttpParams().set("id", object.id);
        var requestUrl = `${href}`
        
        if(object.acctId != undefined){
            params = params.set("acctId", object.acctId.toString());
        }

        return this.http.delete<Message>(requestUrl, {
            params : params
        });
    }

    search(sort: string, order: string, page: number, search: string, limit: number, customFilter: any, columns: any): Observable<SenetData<DeviceProfile>> {
        const href = 'devices/profiles';
        var offset = page * limit
        var requestUrl = `${href}`;

        var params = new HttpParams();

        if(sort != undefined && sort != ""){
            params = params.set("sort", sort);
        }
        if(!isNaN(limit)){
            params = params.set("limit", limit.toString());
        }
        if(order != undefined){
            params = params.set("sortDir", order);          
        }
        if(!isNaN(offset)){
            params = params.set("offset", offset.toString());
        }
        if (search != undefined && search != "") {
            params = params.set("search", search);
        }

        if(customFilter && customFilter.acctId != undefined){
            params = params.set("acctId", customFilter.acctId);
        }
        if (columns != undefined) {
            params = params.set("columns", columns);
        }   
        return this.http.get<SenetData<DeviceProfile>>(requestUrl, {
            params : params
        });
    }
}